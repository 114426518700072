import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthContext";
import ClipLoader from "react-spinners/ClipLoader";
import imageCompression from "browser-image-compression";
import {
  createEvent,
  fetchVenues,
  fetchSponsors,
  fetchSports,
  uploadEventImage,
  downloadFile,
} from "../api/api"; // Import API functions
import ToastNotification from "../components/ToastNotification";
import CropperEventImage from "../components/CropperEventImage";
import Loader from "../components/Loader";
import { FaSyncAlt, FaPlus } from "react-icons/fa";

const CreateEvent = () => {
  const navigate = useNavigate();
  const convertToIST = (date) => {
    // Convert to Date object if it's not already one
    const validDate = date instanceof Date ? date : new Date(date);
    if (isNaN(validDate)) {
      throw new Error("Invalid date provided to convertToIST");
    }
    const ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
    const dateInIST = new Date(validDate.getTime() + ISTOffset);
    return dateInIST.toISOString();
  };

  // Initialize the state with properly formatted datetime values
  const [formData, setFormData] = useState({
    EventTitle: "",
    EventDescription: "",
    EventType: "League", // Default to League, you can change it dynamically
    EventLevel: ["Novice"], // Can be an array of levels
    EventImageFileId: [], // Array of strings for uploaded image IDs
    EventImages: [],
    EventTags: [], // Tags associated with the event
    SportId: "", // The ID of the sport related to the event
    TeamSize: 0, // Maximum number of players allowed
    IsPartnerAllowed: true, // Boolean for partner allowance
    TotalTeams: 0, // Limit on the number of teams
    PriceCurrency: "INR", // Currency for the price, defaulted to INR
    Price: 0, // Price of the event
    PriceCredits: 0, // Price in credits
    isPaid: true, // Boolean indicating if the event is paid
    FAQ: [
      {
        question: "",
        answer: "",
      },
    ],
    VenueId: "",
    // Venue: {
    //   VenueId: "", //Venue Id
    //   VenueName: "",
    //   VenueAddress: {
    //     country: "",
    //     state: "",
    //     city: "",
    //     pincode: "",
    //     landmark: "",
    //   },
    //   VenueRegionId: "", // This will be populated by the selected region
    //   MapLink: "",
    //   VenueDescription: "",
    //   Latitude:0,
    //   Longitude:0,
    // },
    EventSchedule: {
      StartDate: convertToIST(new Date()), // Start date of the event
      EndDate: convertToIST(new Date()), // End date of the event
      StartTimeStamp: convertToIST(new Date(new Date().setHours(0, 0, 0, 0))), // Start timestamp
      EndTimeStamp: convertToIST(new Date(new Date().setHours(0, 0, 0, 0))), // End timestamp
      RegistrationStartDate: convertToIST(new Date()), // Registration start date
      RegistrationEndDate: convertToIST(new Date()), // Registration end date
      RegistrationStartTimeStamp: convertToIST(
        new Date(new Date().setHours(0, 0, 0, 0))
      ), // Registration start timestamp
      RegistrationEndTimeStamp: convertToIST(
        new Date(new Date().setHours(0, 0, 0, 0))
      ), // Registration end timestamp
      IsRecurring: false, // Boolean indicating if the event is recurring
      RecurrenceRule: "DAILY", // Rule for recurrence (e.g., "DAILY", "WEEKLY")
      RecurrenceInterval: 0, // Interval for recurrence (e.g., every 1 day, 1 week)
      RecurrenceEndDate: convertToIST(new Date()), // End date for recurrence
      RecurrenceDaysOfWeek: [], // Days of the week for recurrence (e.g., ["Monday", "Friday"])
    },
    SponsorIds: [],
  });

  const [venues, setVenues] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [sports, setSports] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const [usedCropOptions, setUsedCropOptions] = useState([]);

  const [previewImage, setPreviewImage] = useState(null);
  const [sponsorImages, setSponsorImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState(null);
  const { accessToken } = useAuthContext();
  const [imageLoading, setImageLoading] = useState(false);

  // Fetch required data for Venues, Sponsors, and Sports
  useEffect(() => {
    // Fetch venues
    setLoading(true);
    const fetchVenuesData = async () => {
      try {
        const fetchedVenues = await fetchVenues();
        setVenues(fetchedVenues);
      } catch (error) {
        console.error("Error fetching venues:", error);
      }
    };
    setLoading(false);
    fetchVenuesData();
  }, []); // Only runs once on mount

  useEffect(() => {
    // Fetch sponsors and their images
    setLoading(true);
    const fetchSponsorsData = async () => {
      try {
        const fetchedSponsors = await fetchSponsors();
        setSponsors(fetchedSponsors);

        // Fetch sponsor images
        const imagePromises = fetchedSponsors.map(async (sponsor) => {
          if (sponsor.SponsorImages && sponsor.SponsorImages.length > 0) {
            const imageId = sponsor.SponsorImages[0].ImageId; // Get the first image's ID
            try {
              const imageUrl = await downloadFile(imageId);
              return { SponsorId: sponsor.SponsorId, imageUrl };
            } catch {
              return { SponsorId: sponsor.SponsorId, imageUrl: null };
            }
          }
          return { SponsorId: sponsor.SponsorId, imageUrl: null };
        });

        const images = await Promise.all(imagePromises);
        const imagesById = images.reduce((acc, item) => {
          acc[item.SponsorId] = item.imageUrl;
          return acc;
        }, {});

        setSponsorImages(imagesById);
      } catch (error) {
        console.error("Error fetching sponsors:", error);
      }
    };
    setLoading(false);
    fetchSponsorsData();
  }, []); // Only runs once on mount

  useEffect(() => {
    // Fetch sports
    setLoading(true);
    const fetchSportsData = async () => {
      try {
        const fetchedSports = await fetchSports();
        setSports(fetchedSports);
      } catch (error) {
        console.error("Error fetching sports:", error);
      }
    };
    setLoading(false);
    fetchSportsData();
  }, []); // Only runs once on mount

  // Fetch venue detail based on VenueId
  const fetchVenueInfo = (VenueId) => {
    const selectedVenue = venues.find((venue) => venue.VenueId === VenueId); // Find venue by venueId
    if (selectedVenue) {
      setFormData((prevData) => ({
        ...prevData,
        VenueId: selectedVenue.VenueId,
        // Venue: {
        //   VenueId: selectedVenue.VenueId,
        //   VenueName: selectedVenue.VenueName,
        //   VenueAddress: selectedVenue.VenueAddress,
        //   VenueRegionId: selectedVenue.VenueRegionId,
        //   MapLink: selectedVenue.MapLink,
        //   VenueDescription: selectedVenue.VenueDescription,
        //   Latitude: selectedVenue.Latitude || 0,
        //   Longitude: selectedVenue.Longitude || 0,

        // },
      }));
    }
  };

  // Handle venue change
  const handleVenueChange = (e) => {
    const VenueId = e.target.value;
    fetchVenueInfo(VenueId);
  };

  const refreshVenues = async () => {
    setLoading(true);
    try {
      const fetchedVenues = await fetchVenues();
      setVenues(fetchedVenues);
      setToast({ message: "Venues refreshed successfully!", type: "success" });
    } catch (error) {
      console.error("Error refreshing venues:", error);
      setToast({ message: "Failed to refresh venues.", type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const refreshSponsors = async () => {
    setLoading(true);
    try {
      const fetchedSponsors = await fetchSponsors();
      setSponsors(fetchedSponsors);

      // Fetch images for the new list of sponsors
      const imagePromises = fetchedSponsors.map(async (sponsor) => {
        if (sponsor.SponsorImages && sponsor.SponsorImages.length > 0) {
          const imageId = sponsor.SponsorImages[0].ImageId;
          try {
            const imageUrl = await downloadFile(imageId);
            return { SponsorId: sponsor.SponsorId, imageUrl };
          } catch {
            return { SponsorId: sponsor.SponsorId, imageUrl: null };
          }
        }
        return { SponsorId: sponsor.SponsorId, imageUrl: null };
      });

      const images = await Promise.all(imagePromises);
      const imagesById = images.reduce((acc, item) => {
        acc[item.SponsorId] = item.imageUrl;
        return acc;
      }, {});

      setSponsorImages(imagesById);

      setToast({
        message: "Sponsors refreshed successfully!",
        type: "success",
      });
    } catch (error) {
      console.error("Error refreshing sponsors:", error);
      setToast({ message: "Failed to refresh sponsors.", type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const fetchImageUrl = async (fileId) => {
    try {
      const imageUrl = await downloadFile(fileId);
      return imageUrl;
    } catch (error) {
      console.error("Error fetching image URL:", error);
      return null;
    }
  };

  // Update handleImageCrop function to fetch image URL and set it for preview
  const handleImageCrop = async (blob, tag) => {
    const maxSize = 1 * 1024 * 1024; // 1.5MB in bytes

    // If the image size exceeds the limit, compress it
    let imageBlob = blob;
    if (blob.size > maxSize) {
      try {
        const options = {
          maxSizeMB: 1, // Maximum size in MB
          useWebWorker: true, // Enable web worker for better performance
        };

        setToast({
          message: "Size > 1 MB, Compressing image...",
          type: "info",
        });

        imageBlob = await imageCompression(blob, options);
        console.log("compressed image size:", imageBlob.size / 1024, "KB");

        // Check if the compression was successful
        if (imageBlob.size > maxSize) {
          setToast({
            message: "Image compression failed to reduce size under 1.5MB!",
            type: "error",
          });
          return;
        }

        setToast({
          message: "Image compressed successfully!",
          type: "success",
        });
      } catch (error) {
        console.error("Error compressing image:", error);
        setToast({
          message: "Image compression failed!",
          type: "error",
        });
        return;
      }
    }

    const formData = new FormData();
    formData.append("data", imageBlob);
    formData.append("alt_text", tag);

    setImageLoading(true);

    try {
      const response = await uploadEventImage(formData);
      if (response && response.FileId) {
        const imageUrl = await fetchImageUrl(response.FileId);

        if (imageUrl) {
          setFormData((prevData) => ({
            ...prevData,
            EventImageFileId: [...prevData.EventImageFileId, response.FileId],
            EventImages: [
              ...prevData.EventImages,
              { FileId: response.FileId, Tags: [tag] },
            ],
          }));

          setUsedCropOptions((prevOptions) =>
            prevOptions.includes(tag) ? prevOptions : [...prevOptions, tag]
          );

          setToast({
            message: "Image uploaded successfully!",
            type: "success",
          });
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      console.log(accessToken);
      setToast({ message: "Image upload failed!", type: "error" });
    } finally {
      setShowCropper(false);
      setImageLoading(false);
    }
  };

  const handlePreviewImage = (fileId) => {
    const image = formData.EventImages.find((img) => img.FileId === fileId);
    if (image && image.Url) {
      setPreviewImage(image.Url); // Use the image URL as the source
    }
  };
  // Remove Image
  const handleRemoveImage = (fileId) => {
    const imageToRemove = formData.EventImages.find(
      (image) => image.FileId === fileId
    );

    if (imageToRemove) {
      setUsedCropOptions((prevOptions) =>
        prevOptions.filter((option) => option !== imageToRemove.Tags[0])
      );
    }

    setFormData((prevData) => ({
      ...prevData,
      EventImageFileId: prevData.EventImageFileId.filter((id) => id !== fileId),
      EventImages: prevData.EventImages.filter(
        (image) => image.FileId !== fileId
      ),
    }));
    setToast({ message: "Image removed successfully!", type: "success" });
  };

  // Add this function to check if all crop options are used
  const isAllCropOptionsUsed = () => {
    const allCropOptions = ["SPOTLIGHT", "TALL", "WIDE", "SQUARE"];
    return allCropOptions.every((option) => usedCropOptions.includes(option));
  };

  // Updated Add Image button with disabled state and toast notification
  const handleAddImageClick = () => {
    if (isAllCropOptionsUsed()) {
      setToast({
        message: "All images have been uploaded. No need to add more.",
        type: "info",
      });
    } else {
      setShowCropper(true);
    }
  };

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle adding a new tag
  const handleTagChange = (e) => {
    const value = e.target.value; // Selected tag value
    if (value && !formData.EventTags.includes(value)) {
      // Add the selected tag if it's not already in the array
      setFormData((prevData) => ({
        ...prevData,
        EventTags: [...prevData.EventTags, value],
      }));
    }
    // Reset dropdown selection
    e.target.value = "";
  };

  // Handle removing a tag
  const handleRemoveTag = (tag) => {
    setFormData((prevData) => ({
      ...prevData,
      EventTags: prevData.EventTags.filter((t) => t !== tag),
    }));
  };

  // Ensure TeamSize is not negative
  const handleTeamSizeChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      TeamSize: value >= 0 ? value : prevData.TeamSize,
    }));
  };

  // Ensure TotalTeams is not negative
  const handleTotalTeamsChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      TotalTeams: value >= 0 ? value : prevData.TotalTeams,
    }));
  };

  // Handle checkbox changes for Sponsors
  const handleSponsorChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      SponsorIds: checked
        ? [...prevData.SponsorIds, value]
        : prevData.SponsorIds.filter((id) => id !== value),
    }));
  };

  // Handle checkbox change for isPaid
  const handleIsPaidChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      isPaid: checked,
    }));
  };

  // Handle checkbox change for isPartnerAllowed
  const handleIsPartnerAllowedChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      IsPartnerAllowed: checked,
      TeamSize: !checked ? 1 : prevData.TeamSize, // Set TotalTeams to 1 if Partner Allowed is false
    }));
  };

  // const handleEventDateChange = (e, dateField, timeField) => {
  //   const { value } = e.target; // e.g., "2024-11-30"
  //   const time = formData.EventSchedule[timeField]?.slice(11, 16) || "00:00"; // Extract existing time part (HH:MM) or default to midnight

  //   // Combine date and time into UTC, then convert to IST
  //   const dateInUTC = new Date(`${value}T${time}:00.000Z`);
  //   const ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
  //   const dateInIST = new Date(dateInUTC.getTime() + ISTOffset);

  //   // Format IST date and time
  //   const formattedDate = dateInIST.toISOString().slice(0, 10); // YYYY-MM-DD
  //   const formattedTime = dateInIST.toISOString().slice(11, 16); // HH:MM

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     EventSchedule: {
  //       ...prevData.EventSchedule,
  //       [dateField]: `${formattedDate}T00:00:00.000Z`, // Update date field in IST
  //       [timeField]: `${formattedDate}T${formattedTime}:00.000Z`, // Update time field in IST
  //     },
  //   }));
  // };

  //handle date change
  const handleDateChange = (e, dateField, timeField) => {
    const { value } = e.target; // e.g., "2024-11-30"
    const time = formData.EventSchedule[timeField]?.slice(11, 16) || "00:00"; // Extract existing time or default to midnight

    // Combine date and time into UTC
    const newUTCDateTime = new Date(`${value}T${time}:00.000Z`); // Interpret as UTC

    // Convert back to ISO string for storage
    const newISODateTime = newUTCDateTime.toISOString();

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [dateField]: `${newISODateTime.slice(0, 10)}T00:00:00.000Z`, // Update date field in UTC
        [timeField]: newISODateTime, // Update time field in UTC
      },
    }));
  };
  const handleDateTimeChange = (e, dateTimeField) => {
    const { value } = e.target; // e.g., "2024-11-30"


    // console.log("value: ",value)
    // console.log("new date", new Date(value).toISOString())
    // Combine date and time into UTC


    // Convert back to ISO string for storage
    // const newISODateTime = newUTCDateTime.toISOString();


    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,

        [dateTimeField]: value, // Update time field in UTC
      },
    }));
  };



  //handle time change
  const handleTimeChange = (e, timeField, dateField) => {
    const { value } = e.target; // e.g., "15:00"
    const date = formData.EventSchedule[dateField].slice(0, 10); // Extract the existing date part (YYYY-MM-DD)

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [timeField]: `${date}T${value}:00+05:30`, // Combine date with new time in IST
      },
    }));
  };

  // // Handle Event End Time Change
  // const handleEventEndTimeChange = (e, field) => {
  //   const { value } = e.target; // Time input value, e.g., "18:30"
  //   const currentDate = new Date(formData.EventSchedule.EndDate);

  //   const [hours, minutes] = value.split(":").map(Number);
  //   currentDate.setHours(hours, minutes, 0, 0);

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     EventSchedule: {
  //       ...prevData.EventSchedule,
  //       [field]: currentDate.toISOString(), // Save as plain ISO timestamp
  //     },
  //   }));
  // };

  // Handle Registration Date Change
  // const handleRegistrationDateChange = (e, dateField, timeField) => {
  //   const { value } = e.target; // e.g., "2024-11-30"
  //   const time = formData.EventSchedule[timeField]?.slice(11, 16) || "00:00"; // Extract existing time part (HH:MM) or default to midnight

  //   // Combine date and time into UTC, then convert to IST
  //   const dateInUTC = new Date(`${value}T${time}:00.000Z`);
  //   const ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
  //   const dateInIST = new Date(dateInUTC.getTime() + ISTOffset);

  //   // Format IST date and time
  //   const formattedDate = dateInIST.toISOString().slice(0, 10); // YYYY-MM-DD
  //   const formattedTime = dateInIST.toISOString().slice(11, 16); // HH:MM

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     EventSchedule: {
  //       ...prevData.EventSchedule,
  //       [dateField]: `${formattedDate}T00:00:00.000Z`, // Update date field in IST
  //       [timeField]: `${formattedDate}T${formattedTime}:00.000Z`, // Update time field in IST
  //     },
  //   }));
  // };

  // Handle Registration Start Time Change
  // const handleRegistrationTimeChange = (e, timeField, dateField) => {
  //   const { value } = e.target; // e.g., "15:00"
  //   const date = formData.EventSchedule[dateField].slice(0, 10); // Extract the date part (e.g., "2024-11-30")

  //   // Combine the date and time in IST
  //   const newISTDateTime = `${date}T${value}:00`;
  //   const ISTDateObject = new Date(newISTDateTime + "+05:30"); // Parse as IST

  //   // Convert back to ISO string
  //   const newISODateTime = ISTDateObject.toISOString();

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     EventSchedule: {
  //       ...prevData.EventSchedule,
  //       [timeField]: newISODateTime,
  //       [dateField]: newISODateTime, // Ensure the date matches the timestamp
  //     },
  //   }));
  // };

  const handleFAQChange = (index, e) => {
    const { name, value } = e.target;
    const newFaqs = [...formData.FAQ];
    newFaqs[index] = { ...newFaqs[index], [name]: value };
    setFormData((prevData) => ({
      ...prevData,
      FAQ: newFaqs,
    }));
  };

  const addFAQ = () => {
    setFormData((prevData) => ({
      ...prevData,
      FAQ: [...prevData.FAQ, { question: "", answer: "" }],
    }));
  };

  // Toggle IsRecurring
  const handleIsRecurringChange = (e) => {
    const isRecurring = e.target.checked;

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        IsRecurring: isRecurring,
        ...(isRecurring
          ? {} // If IsRecurring is true, retain current values
          : {
            RecurrenceRule: "",
            RecurrenceInterval: 0,
            RecurrenceEndDate: "",
            RecurrenceDaysOfWeek: [],
          }), // Clear recurrence-related fields if IsRecurring is false
      },
    }));
  };

  // Handle Recurrence Rule Change
  const handleRecurrenceRuleChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      EventSchedule: {
        ...prev.EventSchedule,
        RecurrenceRule: value, // Update RecurrenceRule in state
      },
    }));
  };

  // Handle Recurrence Interval Change
  const handleRecurrenceIntervalChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      EventSchedule: {
        ...prev.EventSchedule,
        RecurrenceInterval: Number(e.target.value),
      },
    }));
  };

  const handleRecurrenceEndDateChange = (e) => {
    const dateValue = e.target.value; // e.g., "2024-12-25"
    const currentTime = formData.EventSchedule.RecurrenceEndDate
      ? formData.EventSchedule.RecurrenceEndDate.slice(11, 16) // Extract time part (HH:MM)
      : "00:00";

    const newISODateTime = `${dateValue}T${currentTime}`;

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        RecurrenceEndDate: newISODateTime,
      },
    }));
  };

  const handleRecurrenceEndTimeChange = (e) => {
    const timeValue = e.target.value; // e.g., "15:30"
    const currentDate = formData.EventSchedule.RecurrenceEndDate
      ? formData.EventSchedule.RecurrenceEndDate.slice(0, 10) // Extract date part (YYYY-MM-DD)
      : convertToIST(new Date()).slice(0, 10); // Default to today's date if none is set

    const newISODateTime = `${currentDate}T${timeValue}`;

    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        RecurrenceEndDate: newISODateTime,
      },
    }));
  };

  // Handle Days of the Week Checkbox Change
  const handleDaysOfWeekChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      EventSchedule: {
        ...prev.EventSchedule,
        RecurrenceDaysOfWeek: checked
          ? [...prev.EventSchedule.RecurrenceDaysOfWeek, value]
          : prev.EventSchedule.RecurrenceDaysOfWeek.filter((d) => d !== value),
      },
    }));
  };

  console.log("form data is", formData);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // // Extract StartDate and StartTimeStamp values from formData
    // const startDate = new Date(formData.EventSchedule.StartDate);
    // const startTime = new Date(formData.EventSchedule.StartTimeStamp);

    // // Combine StartDate and StartTimeStamp into a local datetime string
    // const combinedStartDateTime = new Date(
    //   startDate.getFullYear(),
    //   startDate.getMonth(),
    //   startDate.getDate(),
    //   startTime.getHours(),
    //   startTime.getMinutes(),
    //   startTime.getSeconds()
    // );

    // // Manually create ISO strings without UTC conversion
    // const startDateISOString = `${combinedStartDateTime.getFullYear()}-${String(
    //   combinedStartDateTime.getMonth() + 1
    // ).padStart(2, "0")}-${String(combinedStartDateTime.getDate()).padStart(
    //   2,
    //   "0"
    // )}T00:00:00.000`;

    // const startTimeISOString = `${combinedStartDateTime.getFullYear()}-${String(
    //   combinedStartDateTime.getMonth() + 1
    // ).padStart(2, "0")}-${String(combinedStartDateTime.getDate()).padStart(
    //   2,
    //   "0"
    // )}T${String(combinedStartDateTime.getHours()).padStart(2, "0")}:${String(
    //   combinedStartDateTime.getMinutes()
    // ).padStart(2, "0")}:00.000`;

    // Prepare updatedFormData
    let updatedFormData = {
      ...formData,
      TeamSize: Number(formData.TeamSize),
      TotalTeams: Number(formData.TotalTeams),
      Price: Number(formData.Price),
      PriceCredits: Number(formData.PriceCredits),
      EventLevel: Array.isArray(formData.EventLevel)
        ? formData.EventLevel
        : [formData.EventLevel],
      VenueId: formData.VenueId,
      EventSchedule: {

        ...formData.EventSchedule,
        StartDate: new Date(formData.EventSchedule.StartTimeStamp).toISOString(),
        EndDate: new Date(formData.EventSchedule.StartTimeStamp).toISOString(),
        StartTimeStamp: new Date(formData.EventSchedule.StartTimeStamp).toISOString(),
        EndTimeStamp: new Date(formData.EventSchedule.EndTimeStamp).toISOString(),
        RegistrationStartDate: new Date(formData.EventSchedule.RegistrationStartTimeStamp).toISOString(),
        RegistrationStartTimeStamp: new Date(formData.EventSchedule.RegistrationStartTimeStamp).toISOString(),
        RegistrationEndDate: new Date(formData.EventSchedule.RegistrationEndTimeStamp).toISOString(),
        RegistrationEndTimeStamp: new Date(formData.EventSchedule.RegistrationEndTimeStamp).toISOString(),
        // StartDate: startDateISOString, // Use local StartDate
        // StartTimeStamp: startTimeISOString, // Use local StartTimeStamp
        // Ensure recurrence fields have default values when IsRecurring is false
        RecurrenceEndDate: new Date(formData.EventSchedule.RecurrenceEndDate).toISOString(),
        RecurrenceRule: formData.EventSchedule.IsRecurring
          ? formData.EventSchedule.RecurrenceRule
          : null,
        RecurrenceInterval: formData.EventSchedule.IsRecurring
          ? formData.EventSchedule.RecurrenceInterval
          : 0,
        RecurrenceEndDate: formData.EventSchedule.IsRecurring
          ? formData.EventSchedule.RecurrenceEndDate
          : null,
        RecurrenceDaysOfWeek: formData.EventSchedule.IsRecurring
          ? formData.EventSchedule.RecurrenceDaysOfWeek
          : [],
      },
    };

    console.log("Updated form data", updatedFormData)

    // Validate at least one image
    if (
      !updatedFormData.EventImageFileId ||
      updatedFormData.EventImageFileId.length === 0
    ) {
      setToast({
        message: "Please upload at least one event image.",
        type: "error",
      });
      return;
    }

    if (formData.Price < 1 || formData.PriceCredits < 1) {
      setToast({
        message: "Price and Price Credits must be greater than 0",
        type: "error",
      });
      return;
    }
    if (formData.TeamSize < 1 || formData.TotalTeams < 1) {
      setToast({
        message: "Team Size and Total Teams must be greater than 0",
        type: "error",
      });
      return;
    }


    try {
      await createEvent(updatedFormData);
      setToast({ message: "Event created successfully!", type: "success" });
      console.log("Payload to be sent:", updatedFormData);
      navigate("/events");
    } catch (error) {
      console.error("Error creating event:", error);
      console.log("Payload to be sent:", updatedFormData);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-center">Create New Event</h2>
      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
      <form onSubmit={handleSubmit}>
        {/* Event Type */}
        <div className="mb-6">
          <label
            htmlFor="EventType"
            className="block text-lg font-semibold mb-2"
          >
            Event Type
          </label>
          <select
            name="EventType"
            id="EventType"
            value={formData.EventType}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
          >
            <option value="League">League</option>
            <option value="Tournament">Tournament</option>
            <option value="Bootcamp">Bootcamp</option>
            <option value="Social">Social</option>
          </select>
        </div>

        {/* Event Title */}
        <div className="mb-6">
          <label
            htmlFor="EventTitle"
            className="block text-lg font-semibold mb-2"
          >
            Event Title *
          </label>
          <input
            type="text"
            name="EventTitle"
            id="EventTitle"
            value={formData.EventTitle}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Event Description */}
        <div className="mb-6">
          <label
            htmlFor="EventDescription"
            className="block text-lg font-semibold mb-2"
          >
            Event Description *
          </label>
          <textarea
            name="EventDescription"
            id="EventDescription"
            value={formData.EventDescription}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Uploaded Images */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-4">Uploaded Images</h3>
          {formData.EventImages.length === 0 ? (
            <p className="text-gray-500">No images uploaded yet.</p>
          ) : (
            <ul className="space-y-2">
              {formData.EventImages.map((image, index) => (
                <li
                  key={image.FileId}
                  className="flex items-center justify-between bg-gray-100 p-3 rounded-lg"
                >
                  <div>
                    <p className="font-medium">Image {index + 1}</p>
                    <p className="text-sm text-gray-500">
                      Tags: {image.Tags.join(", ")}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    {/* Preview Button */}
                    <button
                      type="button" // Prevents form submission
                      onClick={() => handlePreviewImage(image.FileId)}
                      className="text-blue-500 hover:underline"
                    >
                      Preview
                    </button>
                    {/* Remove Button */}
                    <button
                      type="button" // Prevents form submission
                      onClick={() => handleRemoveImage(image.FileId)}
                      className="text-red-500 hover:underline"
                    >
                      Remove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}

          {/* Image Preview Overlay */}
          {previewImage && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-4 rounded-lg shadow-lg relative max-w-lg w-full max-h-[80vh] overflow-auto">
                <button
                  type="button"
                  onClick={() => setPreviewImage(null)}
                  className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 text-2xl"
                >
                  ✕
                </button>
                <div className="flex items-center justify-center">
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="max-w-full max-h-[60vh] object-contain"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Add Image Button */}
        <div className="mb-6">
          <button
            type="button"
            onClick={handleAddImageClick}
            disabled={isAllCropOptionsUsed()}
            className={`bg-blue-500 text-white p-2 rounded-lg transition duration-200 ${isAllCropOptionsUsed()
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-600"
              }`}
          >
            {imageLoading ? (
              <>
                <div className="w-30 gap-x-1 flex items-center">
                  <p>Uploading</p>{" "}
                  <ClipLoader color={"white"} loading={true} size={20} />
                </div>
              </>
            ) : (
              "Add Image"
            )}
          </button>
        </div>

        {/* Cropping Tool Overlay */}
        {showCropper && (
          <CropperEventImage
            onCropComplete={handleImageCrop}
            onClose={() => setShowCropper(false)}
            usedCropOptions={usedCropOptions}
          />
        )}

        {/* EventTags Dropdown */}
        <div className="mb-6">
          <label
            htmlFor="EventTags"
            className="block text-lg font-semibold mb-2"
          >
            Event Tags
          </label>
          {/* Dropdown for selecting a tag */}
          <select
            id="EventTags"
            onChange={handleTagChange} // Call the handler to add the tag
            className="w-full p-3 border border-gray-300 rounded-lg"
          >
            <option value="">Select a Tag</option> {/* Placeholder */}
            <option value="SPOTLIGHT">Spotlight</option>
          </select>

          {/* Display selected tags */}
          <div className="mt-4 flex flex-wrap gap-2">
            {formData.EventTags.map((tag, index) => (
              <span
                key={index}
                className="flex items-center bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm shadow-md"
              >
                {tag}
                <button
                  type="button"
                  onClick={() => handleRemoveTag(tag)} // Remove tag on click
                  className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                >
                  ✕
                </button>
              </span>
            ))}
          </div>
        </div>

        {/* Sponsors Checkbox List */}
        <div className="mb-6">
          <div className="flex items-center justify-between mb-2">
            <label
              htmlFor="SponsorIds"
              className="block text-lg font-semibold mb-2"
            >
              Sponsors
            </label>
            <div className="flex space-x-3">
              <button
                type="button"
                onClick={refreshSponsors}
                className="text-gray-500 hover:text-gray-700 flex items-center justify-center transition duration-200 p-2 rounded-lg"
              >
                <FaSyncAlt className="text-xl" />
              </button>
              <button
                type="button"
                onClick={() => window.open("/sponsors/create", "_blank")}
                className="text-gray-500 hover:text-gray-700 flex items-center justify-center transition duration-200 p-2 rounded-lg"
              >
                <FaPlus className="text-xl" />
              </button>
            </div>
          </div>
          <div className="flex flex-wrap gap-4">
            {sponsors.map((sponsor) => (
              <div
                key={sponsor.SponsorId}
                className="flex items-center space-x-4 p-2 border rounded-lg shadow-sm bg-gray-50"
              >
                {/* Sponsor Image */}
                {sponsorImages[sponsor.SponsorId] ? (
                  <img
                    src={sponsorImages[sponsor.SponsorId]}
                    alt={sponsor.SponsorName}
                    className="w-12 h-12 object-cover rounded-full"
                  />
                ) : (
                  <div className="w-12 h-12 bg-gray-300 rounded-full flex items-center justify-center">
                    <span className="text-sm text-gray-600">No Image</span>
                  </div>
                )}

                {/* Checkbox and Name */}
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id={`sponsor-${sponsor.SponsorId}`}
                      name="SponsorIds"
                      value={sponsor.SponsorId}
                      checked={formData.SponsorIds.includes(sponsor.SponsorId)}
                      onChange={handleSponsorChange}
                      className="h-5 w-5"
                    />
                    <label
                      htmlFor={`sponsor-${sponsor.SponsorId}`}
                      className="text-lg font-medium"
                    >
                      {sponsor.SponsorName} ({sponsor.SponsorType})
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Sport Dropdown */}
        <div className="mb-6">
          <label htmlFor="SportId" className="block text-lg font-semibold mb-2">
            Sport *
          </label>
          <select
            name="SportId"
            id="SportId"
            value={formData.SportId}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select a Sport</option>
            {sports.map((sport) => (
              <option key={sport.SportId} value={sport.SportId}>
                {sport.SportType}
              </option>
            ))}
          </select>
        </div>

        {/* Max Players aka Team size */}
        <div className="mb-6">
          <label
            htmlFor="TeamSize"
            className="block text-lg font-semibold mb-2"
          >
            Team Size
          </label>
          <input
            type="text"
            name="TeamSize"
            id="TeamSize"
            value={formData.TeamSize}
            onChange={handleTeamSizeChange}
            min="1" // Prevent negative values
            className="w-full p-3 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Team Limit aka Total Teams */}
        <div className="mb-6">
          <label
            htmlFor="TotalTeams"
            className="block text-lg font-semibold mb-2"
          >
            Total Teams
          </label>
          <input
            type="text"
            name="TotalTeams"
            id="TotalTeams"
            value={formData.TotalTeams}
            onChange={handleTotalTeamsChange}
            min="1" // Prevent negative values
            className="w-full p-3 border border-gray-300 rounded-lg"
          />
        </div>

        {/* isPartnerAllowed Checkbox */}
        <div className="mb-6">
          <label
            htmlFor="IsPartnerAllowed"
            className="block text-lg font-semibold mb-2"
          >
            Is Partner Allowed
          </label>
          <input
            type="checkbox"
            name="IsPartnerAllowed"
            id="IsPartnerAllowed"
            checked={formData.IsPartnerAllowed}
            onChange={handleIsPartnerAllowedChange}
            className="mr-2"
          />
        </div>

        {/* Event Start Date & Time */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">
            Event Start Date & Time *
          </label>
          <div className="flex gap-4">
            {/* Start Date Input */}
            <input
              type="datetime-local"
              name="StartTimeStamp"
              value={formData.EventSchedule.StartTimeStamp
              }
              min={convertToIST(new Date())}
              onChange={(e) =>
                handleDateTimeChange(e, "StartTimeStamp")
              }
              className="w-1/2 p-3 border border-gray-300 rounded-lg"
              required
            />

          </div>
        </div>

        {/* Event End Date & Time */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">
            Event End Date & Time *
          </label>
          <div className="flex gap-4">
            {/* End Date Input */}
            <input
              type="datetime-local"
              name="EndTimeStamp"
              value={formData.EventSchedule.EndTimeStamp}
              min={convertToIST(new Date())}
              onChange={(e) =>
                handleDateTimeChange(e, "EndTimeStamp")
              }
              className="w-1/2 p-3 border border-gray-300 rounded-lg"
              required
            />

          </div>
        </div>

        {/* Registration Start Date & Time */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">
            Registration Start Date & Time *
          </label>
          <div className="flex gap-4">
            {/* Start Date Input */}
            <input
              type="datetime-local"
              name="RegistrationStartTimeStamp"
              value={formData.EventSchedule.RegistrationStartTimeStamp}
              min={convertToIST(new Date())}
              onChange={(e) =>
                handleDateTimeChange(
                  e,

                  "RegistrationStartTimeStamp"
                )
              }
              className="w-1/2 p-3 border border-gray-300 rounded-lg"
              required
            />

          </div>
        </div>

        {/* Registration End Date & Time */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">
            Registration End Date & Time *
          </label>
          <div className="flex gap-4">
            {/* End Date Input */}
            <input
              type="datetime-local"
              name="RegistrationEndTimeStamp"
              value={formData.EventSchedule.RegistrationEndTimeStamp}
              min={convertToIST(new Date())}
              onChange={(e) =>
                handleDateTimeChange(
                  e,
                  "RegistrationEndTimeStamp"
                )
              }
              className="w-1/2 p-3 border border-gray-300 rounded-lg"
              required
            />

          </div>
        </div>

        {/* Recurrence Details */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">
            Is Recurring Event?
          </label>
          <input
            type="checkbox"
            name="IsRecurring"
            checked={formData.EventSchedule.IsRecurring}
            onChange={handleIsRecurringChange} // Call the handler here
            className="mr-2"
          />
        </div>

        {/* Recurrence Rule */}
        {formData.EventSchedule.IsRecurring && (
          <>
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Recurrence Rule
              </label>
              <select
                name="RecurrenceRule"
                value={formData.EventSchedule.RecurrenceRule} // Bind to state
                onChange={handleRecurrenceRuleChange} // Update state on change
                className="w-full p-3 border border-gray-300 rounded-lg"
              >
                <option value="">Select Recurrence Rule</option>{" "}
                {/* Default placeholder */}
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
                <option value="MONTHLY">Monthly</option>
              </select>
            </div>

            {/* Recurrence Interval */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Recurrence Interval (Number of Days/Weeks/Months)
              </label>
              <input
                type="number"
                name="RecurrenceInterval"
                value={formData.EventSchedule.RecurrenceInterval}
                onChange={handleRecurrenceIntervalChange} // Call the handler here
                className="w-full p-3 border border-gray-300 rounded-lg"
                min="1"
              />
            </div>
            {/* Recurrence End Date & Time */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Recurrence End Date & Time
              </label>
              <div className="flex gap-4">

                {/* Recurrence End Time Input */}
                <input
                  type="datetime-local"
                  name="RecurrenceEndDate"
                  value={formData.EventSchedule.RecurrenceEndDate}
                  onChange={(e) =>
                    handleDateTimeChange(
                      e,
                      "RecurrenceEndDate"
                    )
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />
              </div>
            </div>

            {/* Recurrence Days of the Week */}
            {formData.EventSchedule.RecurrenceRule === "WEEKLY" && (
              <div className="mb-6">
                <label className="block text-lg font-semibold mb-2">
                  Recurrence Days of the Week
                </label>
                <div className="grid grid-cols-2 gap-4">
                  {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((day) => (
                    <div key={day} className="flex items-center">
                      <input
                        type="checkbox"
                        id={day}
                        name="RecurrenceDaysOfWeek"
                        value={day}
                        checked={formData.EventSchedule.RecurrenceDaysOfWeek.includes(
                          day
                        )}
                        onChange={handleDaysOfWeekChange} // Call the handler here
                        className="mr-2"
                      />
                      <label htmlFor={day} className="text-gray-700">
                        {day}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}

        {/* Venue Dropdown */}
        <div className="mb-6">
          <div className="flex items-center justify-between mb-2">
            <label htmlFor="VenueId" className="block text-lg font-semibold">
              Location *
            </label>
            <div className="flex space-x-3">
              <button
                type="button"
                onClick={refreshVenues}
                className="text-gray-500 hover:text-gray-700 flex items-center justify-center transition duration-200 p-2 rounded-lg"
              >
                <FaSyncAlt className="text-xl" />
              </button>
              <button
                type="button"
                onClick={() => window.open("/venues/create", "_blank")}
                className="text-gray-500 hover:text-gray-700 flex items-center justify-center transition duration-200 p-2 rounded-lg"
              >
                <FaPlus className="text-xl" />
              </button>
            </div>
          </div>
          <select
            name="VenueId"
            id="VenueId"
            value={formData.VenueId}
            onChange={handleVenueChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select a Venue</option>
            {venues.map((venue) => (
              <option key={venue.VenueId} value={venue.VenueId}>
                {venue.VenueName}
              </option>
            ))}
          </select>
        </div>

        {/* Price and isPaid Checkbox */}
        <div className="mb-6">
          <label htmlFor="isPaid" className="block text-lg font-semibold mb-2">
            Is Paid
          </label>
          <input
            type="checkbox"
            name="isPaid"
            id="isPaid"
            checked={formData.isPaid}
            onChange={handleIsPaidChange}
            className="mr-2"
          />
          {formData.isPaid && (
            <>
              <label
                htmlFor="PriceINR"
                className="block text-lg font-semibold mb-2"
              >
                Price (in INR)
              </label>
              <input
                type="text"
                name="Price"
                id="PriceINR"
                value={formData.Price}
                onChange={handleInputChange}
                min="1"
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
              <label
                htmlFor="PriceCredits"
                className="block text-lg font-semibold mb-2"
              >
                Price (in Credits)
              </label>
              <input
                type="text"
                name="PriceCredits"
                id="PriceCredits"
                value={formData.PriceCredits}
                onChange={handleInputChange}
                onWheel={(e) => e.preventDefault()}
                min="1"
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </>
          )}
        </div>

        {/* Cost in Credits section will be added here once It's added in API*/}

        {/* Event Level */}
        <div className="mb-6">
          <label
            htmlFor="EventLevel"
            className="block text-lg font-semibold mb-2"
          >
            Event Level
          </label>
          <select
            name="EventLevel"
            id="EventLevel"
            value={formData.EventLevel}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
          >
            <option value="Novice">Novice</option>
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Expert">Expert</option>
          </select>
        </div>

        {/* FAQ Section */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">FAQs</label>
          {formData.FAQ.map((faq, index) => (
            <div key={index} className="mb-4">
              <input
                type="text"
                name="question"
                placeholder="Question"
                value={faq.question}
                onChange={(e) => handleFAQChange(index, e)}
                className="w-full p-3 border border-gray-300 rounded-lg mb-2"
              />
              <textarea
                name="answer"
                placeholder="Answer"
                value={faq.answer}
                onChange={(e) => handleFAQChange(index, e)}
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={addFAQ}
            className="bg-green-500 text-white p-2 rounded-lg"
          >
            Add FAQ
          </button>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-blue-500 text-white p-3 rounded-lg mt-4 w-full"
        >
          Create Event
        </button>
      </form>
    </div>
  );
};

export default CreateEvent;