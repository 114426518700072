import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchEvents, deleteEvent } from "../api/api";
import { FaPlus, FaTrashAlt, FaFilter, FaTimes } from "react-icons/fa";
import Loader from "../components/Loader";

const EventsList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [filteredEvents, setFilteredEvents] = useState([]); // State for filtered events
  const [sortOption, setSortOption] = useState(""); // Default no sort option
  const [showDropdown, setShowDropdown] = useState(false); // For controlling dropdown visibility

  // Fetch events when the component mounts
  useEffect(() => {
    const loadEvents = async () => {
      setLoading(true); // Show loader
      try {
        const eventsData = await fetchEvents("asc", 100, 0);
        setEvents(eventsData.events);
        setFilteredEvents(eventsData.events); // Initialize filtered events
        setError(null); // Reset error if the fetch is successful
      } catch (error) {
        console.error("Error fetching events:", error);
        setEvents([]); // Ensure events is an empty array on failure
        setFilteredEvents([]); // Reset filtered events
        setError(null); // Reset error to avoid showing "Failed to load events"
      } finally {
        setLoading(false); // Hide loader
      }
    };

    loadEvents();
  }, []);

  // Filter and sort events
  useEffect(() => {
    let updatedEvents = [...events];

    // Apply sorting
    if (sortOption === "registration") {
      updatedEvents.sort((a, b) => {
        const dateA = new Date(a.EventSchedule?.RegistrationEndDate || Infinity);
        const dateB = new Date(b.EventSchedule?.RegistrationEndDate || Infinity);
        return dateA - dateB;
      });
    } else if (sortOption === "startdate") {
      updatedEvents.sort((a, b) => {
        const dateA = new Date(a.EventSchedule?.StartDate || Infinity);
        const dateB = new Date(b.EventSchedule?.StartDate || Infinity);
        return dateA - dateB;
      });
    }

    // Apply search query filtering
    if (searchQuery) {
      updatedEvents = updatedEvents.filter((event) =>
        event.EventTitle?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredEvents(updatedEvents);
  }, [searchQuery, events, sortOption]);

  // Handle event deletion
  const handleDeleteEvent = async (eventId) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        // const token = localStorage.getItem("auth")
        //   ? JSON.parse(localStorage.getItem("auth")).token
        //   : null;
  
        // if (!token) {
        //   alert("Access token is missing. Please log in.");
        //   return;
        // }
  
        await deleteEvent(eventId); // Pass the token to the deleteEvent API
        setEvents((prevEvents) =>
          prevEvents.filter((event) => event.EventId !== eventId)
        );
        alert("Event deleted successfully!");
      } catch (error) {
        console.error("Error deleting Event:", error);
        alert("Failed to delete Event. Please try again.");
      }
    }
  };
  
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="event-list bg-gray-100 min-h-screen p-6">
      <div className="flex justify-between items-center mb-6">
        {/* Heading */}
        <h1 className="text-3xl font-bold sm:text-4xl text-gray-800">
          All Events
        </h1>

        {/* Create Event Button */}
        <Link
          to="/events/create"
          className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white p-3 rounded-lg shadow-lg hover:bg-indigo-700 flex items-center justify-center sm:w-auto w-12 h-12"
        >
          <FaPlus size={20} className="sm:hidden" />
          <span className="hidden sm:inline-block ml-2 text-base sm:text-lg">
            Create Event
          </span>
        </Link>
      </div>

      {/* Search and Sort Controls */}
      <div className="mb-6 flex justify-between items-center">
        {/* Search Box */}
        <input
          type="text"
          placeholder="Search events by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full sm:w-1/2 md:w-1/3 px-4 py-2 border border-gray-300 rounded-lg shadow-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
        />

        {/* Dropdown Button with Cross Icon */}
        <div className="relative flex items-center">
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className={`px-4 py-2 rounded-lg shadow-md focus:ring-2 focus:ring-indigo-500 focus:outline-none flex items-center gap-2 ${
              sortOption
                ? "bg-indigo-500 text-white hover:bg-indigo-600"
                : "bg-gray-200 text-gray-600 hover:bg-gray-300"
            }`}
          >
            <FaFilter />
            <span className="hidden sm:inline">Filter/Sort</span>
          </button>

          {/* Cross Icon to Remove Filter */}
          {sortOption && (
            <button
              onClick={() => setSortOption("")}
              className="ml-2 bg-red-100 text-red-500 hover:bg-red-200 rounded-full p-2 shadow transition duration-200"
              title="Remove Filter"
            >
              <FaTimes />
            </button>
          )}

          {showDropdown && (
            <div className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-lg z-10">
              <ul className="py-2">
                <li
                  onClick={() => {
                    setSortOption("registration");
                    setShowDropdown(false);
                  }}
                  className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                    sortOption === "registration" ? "font-semibold" : ""
                  }`}
                >
                  Registration End Date (Closest First)
                </li>
                <li
                  onClick={() => {
                    setSortOption("startdate");
                    setShowDropdown(false);
                  }}
                  className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                    sortOption === "startdate" ? "font-semibold" : ""
                  }`}
                >
                  Event Start Date (Closest First)
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Event Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {filteredEvents.length ? (
          filteredEvents.map((event) => (
            <div
              key={event.EventId}
              className="event-card p-4 bg-white shadow-lg rounded-lg hover:shadow-2xl transition duration-200 relative"
            >
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                {event.EventTitle}
              </h3>
              <p className="text-gray-600 text-sm">
                {event.EventDescription.length > 70
                  ? `${event.EventDescription.slice(0, 70)}...`
                  : event.EventDescription}
              </p>

              {/* Event Tags */}
              {event.EventTags?.length > 0 && (
                <div className="mt-4 flex flex-wrap gap-2">
                  {event.EventTags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-blue-100 text-blue-800 px-2 py-1 text-xs rounded-full shadow-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}
              <Link
                to={`/events/details/${event.EventId}`}
                className="text-indigo-500 hover:text-indigo-600 mt-4 inline-block font-medium"
              >
                View Details
              </Link>
              <button
                onClick={() => handleDeleteEvent(event.EventId)}
                className="absolute top-2 right-2 bg-red-100 text-red-500 hover:bg-red-200 rounded-full p-2 shadow transition duration-200"
                title="Delete Event"
              >
                <FaTrashAlt size={16} />
              </button>
            </div>
          ))
        ) : (
          <p className="text-gray-700 text-center col-span-3">
            No events found matching the search query
          </p>
        )}
      </div>
    </div>
  );
};

export default EventsList;
