import React, { useState, useEffect } from "react";
import { fetchVenues, createVenueSlot, fetchSports } from "../api/api";
import { useNavigate } from "react-router-dom";
import ToastNotification from "../components/ToastNotification";

const CreateVenueSlot = () => {
  const navigate = useNavigate();

  const [venues, setVenues] = useState([]);
  const [sports, setSports] = useState([]);
  const [selectedVenueId, setSelectedVenueId] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const [formData, setFormData] = useState({
    PlayArea: "",
    Slot: "",
    CostPerFormat: [],
    Duration: "",
    Schedule: {
      StartTimeStamp: "",
      EndTimeStamp: "",
      IsRecurring: false,
      RecurrenceRule: "" || null,
      RecurrenceInterval: 0,
      RecurrenceEndDate: "",
      RecurrenceDaysOfWeek: [],
    },
  });
  const [toast, setToast] = useState(null);

  // Fetch all venues
  useEffect(() => {
    const getVenues = async () => {
      try {
        const data = await fetchVenues();
        setVenues(data);
      } catch (error) {
        setToast({ message: "Failed to fetch venues.", type: "error" });
      }
    };

    getVenues();
  }, []);

  useEffect(() => {
    const getSports = async () => {
      try {
        const data = await fetchSports();
        setSports(data);
      } catch (e) {
        setToast({ message: "Failed to fetch sports.", type: "error" });
      }
    };
    getSports();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCostPerFormatChange = (index, field, value) => {
    const updatedCostPerFormat = [...formData.CostPerFormat];
    updatedCostPerFormat[index][field] = value;

    setFormData({
      ...formData,
      CostPerFormat: updatedCostPerFormat,
    });
  };

  // Handle schedule input changes
  const handleScheduleChange = (e) => {
    const { name, value } = e.target;
  
    // Convert back to ISO string for storage
    // const newISODateTime = newUTCDateTime.toISOString();
    setFormData({
      ...formData,
      Schedule: {
        ...formData.Schedule,
        [name]: value,
      },
    });
  };

  // Recurrence Handlers
  const handleIsRecurringChange = (e) => {
    const isRecurring = e.target.checked;

    setFormData((prevData) => ({
      ...prevData,
      Schedule: {
        ...prevData.Schedule,
        IsRecurring: isRecurring,
        ...(isRecurring
          ? {}
          : {
              RecurrenceRule: "",
              RecurrenceInterval: 0,
              RecurrenceEndDate: "",
              RecurrenceDaysOfWeek: [],
            }),
      },
    }));
  };

  const handleRecurrenceRuleChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      Schedule: {
        ...prev.Schedule,
        RecurrenceRule: value,
      },
    }));
  };

  const handleRecurrenceIntervalChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      Schedule: {
        ...prev.Schedule,
        RecurrenceInterval: Number(e.target.value),
      },
    }));
  };

  const handleRecurrenceEndDateChange = (e) => {
    const dateValue = e.target.value;
    const currentTime = formData.Schedule.RecurrenceEndDate
      ? formData.Schedule.RecurrenceEndDate.slice(11, 16)
      : "00:00";

    const newISODateTime = `${dateValue}T${currentTime}`;
    setFormData((prevData) => ({
      ...prevData,
      Schedule: {
        ...prevData.Schedule,
        RecurrenceEndDate: newISODateTime,
      },
    }));
  };

  const handleRecurrenceDaysChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      Schedule: {
        ...prev.Schedule,
        RecurrenceDaysOfWeek: checked
          ? [...prev.Schedule.RecurrenceDaysOfWeek, value]
          : prev.Schedule.RecurrenceDaysOfWeek.filter((day) => day !== value),
      },
    }));
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      VenueId: selectedVenueId,
      SportId: selectedSportId,
      PlayArea: formData.PlayArea,
      // Slot: `${formData.Slot}:00.000Z`,
      CostPerFormat: formData.CostPerFormat.map((cost) => ({
        Format: Number(cost.Format),
        Price: Number(cost.Price),
        PriceCredits: Number(cost.PriceCredits),
      })),
      Duration: Number(formData.Duration),
      Schedule: {
        ...formData.Schedule,
        StartTimeStamp: new Date(formData.Schedule.StartTimeStamp).toISOString(),
        EndTimeStamp: new Date(formData.Schedule.EndTimeStamp).toISOString(),
        RecurrenceEndDate: formData.Schedule.IsRecurring
          ? new Date(formData.Schedule.RecurrenceEndDate).toISOString()
          : null,
      },
    };

    try {
      await createVenueSlot(selectedVenueId, payload);
      setToast({
        message: "Venue slot created successfully!",
        type: "success",
      });
      navigate("/venues");
    } catch (error) {
      console.log("Payload to be sent", payload);
      setToast({ message: "Failed to create venue slot.", type: "error" });
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Create Venue Slots</h2>

      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <form onSubmit={handleSubmit}>
        {/* Venue Dropdown */}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">
            Select Venue
          </label>
          <select
            value={selectedVenueId}
            onChange={(e) => setSelectedVenueId(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select a Venue</option>
            {venues.map((venue) => (
              <option key={venue.VenueId} value={venue.VenueId}>
                {venue.VenueName}
              </option>
            ))}
          </select>
        </div>
        {/*Sports Dropdown*/}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">
            Select Sport
          </label>
          <select
            value={selectedSportId}
            onChange={(e) => setSelectedSportId(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg"
          >
            <option value="">Select a Sport</option>
            {sports.map((sport) => (
              <option key={sport.SportId} value={sport.SportId}>
                {sport.SportType}
              </option>
            ))}
          </select>
        </div>

        {/* Add Duration */}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">
            Duration(in minutes)
          </label>
          <select
            name="Duration"
            value={formData.Duration}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="30">30 Minutes</option>
            <option value="60">1 Hour</option>
            <option value="90">1 Hour 30 Minutes</option>
            <option value="120">2 Hours</option>
          </select>
        </div>

        {/* Play Area */}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">Play Area</label>
          <input
            type="text"
            name="PlayArea"
            value={formData.PlayArea}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Slot */}
        {/* <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">Slot</label>
          <input
            type="datetime-local"
            name="Slot"
            value={formData.Slot.slice(0, 16)}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div> */}

        {/* CostPerFormat Section */}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">
            Cost Per Format
          </label>
          {formData.CostPerFormat.map((cost, index) => (
            <div key={index} className="mb-4 border p-3 rounded-lg bg-gray-50">
              <div className="mb-2">
                <label className="block text-sm font-semibold mb-1">
                  Format
                </label>
                <select
                  value={cost.Format}
                  onChange={(e) =>
                    handleCostPerFormatChange(index, "Format", e.target.value)
                  }
                  className="w-full p-2 border border-gray-300 rounded-lg"
                >
                  <option value="1">1v1</option>
                  <option value="2">2v2</option>
                  {/* <option value="3">Format 3</option> */}
                </select>
              </div>
              <div className="mb-2">
                <label className="block text-sm font-semibold mb-1">
                  Price (INR)
                </label>
                <input
                  type="number"
                  value={cost.Price}
                  onChange={(e) =>
                    handleCostPerFormatChange(index, "Price", e.target.value)
                  }
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  min="0"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold mb-1">
                  Price Credits
                </label>
                <input
                  type="number"
                  value={cost.PriceCredits}
                  onChange={(e) =>
                    handleCostPerFormatChange(
                      index,
                      "PriceCredits",
                      e.target.value
                    )
                  }
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  min="0"
                />
              </div>
              {formData.CostPerFormat.length > 1 && (
                <button
                  type="button"
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      CostPerFormat: prev.CostPerFormat.filter(
                        (_, i) => i !== index
                      ),
                    }))
                  }
                  className="text-red-500 mt-2 hover:underline text-sm"
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={() =>
              setFormData((prev) => ({
                ...prev,
                CostPerFormat: [
                  ...prev.CostPerFormat,
                  { Format: 1, Price: 0, PriceCredits: 0 },
                ],
              }))
            }
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
          >
            Add Another Format
          </button>
        </div>

        {/* Schedule Start Time */}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">
            Schedule Start Time
          </label>
          <input
            type="datetime-local"
            name="StartTimeStamp"
            value={formData.Schedule.StartTimeStamp.slice(0, 16)}
            onChange={handleScheduleChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Schedule End Time */}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">
            Schedule End Time
          </label>
          <input
            type="datetime-local"
            name="EndTimeStamp"
            value={formData.Schedule.EndTimeStamp.slice(0, 16)}
            onChange={handleScheduleChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Toggle Recurrence */}
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.Schedule.IsRecurring}
              onChange={handleIsRecurringChange}
              className="mr-2"
            />
            Recurring Slot
          </label>
        </div>

        {/* Recurrence Options */}
        {formData.Schedule.IsRecurring && (
          <>
            <div className="mb-4">
              <label className="block font-semibold mb-2">
                Recurrence Rule
              </label>
              <select
                value={formData.Schedule.RecurrenceRule}
                onChange={handleRecurrenceRuleChange}
                className="w-full p-3 border rounded"
              >
                <option value="">Select Rule</option>
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
                <option value="MONTHLY">Monthly</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block font-semibold mb-2">
                Recurrence Interval
              </label>
              <input
                type="number"
                value={formData.Schedule.RecurrenceInterval}
                onChange={handleRecurrenceIntervalChange}
                className="w-full p-3 border rounded"
                min="1"
              />
            </div>

            <div className="mb-4">
              <label className="block font-semibold mb-2">
                Recurrence End Date
              </label>
              <input
                type="date"
                value={formData.Schedule.RecurrenceEndDate.slice(0, 10)}
                onChange={handleRecurrenceEndDateChange}
                className="w-full p-3 border rounded"
              />
            </div>

            {/* Recurrence Days of Week - Only for Weekly */}
            {formData.Schedule.RecurrenceRule === "WEEKLY" && (
              <div className="mb-4">
                <label className="block font-semibold mb-2">
                  Recurrence Days of the Week
                </label>
                {[
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ].map((day) => (
                  <label key={day} className="inline-flex items-center mr-4">
                    <input
                      type="checkbox"
                      value={day}
                      checked={formData.Schedule.RecurrenceDaysOfWeek.includes(
                        day
                      )}
                      onChange={handleRecurrenceDaysChange}
                      className="mr-2"
                    />
                    {day}
                  </label>
                ))}
              </div>
            )}
          </>
        )}

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
        >
          Create Slot
        </button>
      </form>
    </div>
  );
};

export default CreateVenueSlot;
