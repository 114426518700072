import React, { useState, useEffect } from "react";
import ToastNotification from "../components/ToastNotification"; // Assuming a toast component exists
import { createUser, fetchSports } from "../api/api";
import { useNavigate } from "react-router-dom";


const CreateUser = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    ext: "+91",
    gender: "",
    first_name: "",
    last_name: "",
    org_id: null,
    role: "",
    Sport: "",
  });

  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const fetchSport = async () => {
      try {
        const fetchedsports = await fetchSports();
        setSports(fetchedsports);
      } catch (err) {
        console.error("Error fetching sports:", err);
      }
    };
    fetchSport();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setToast(null);

    try {
      await createUser(formData);
      console.log("create user payload",formData)
      setToast({ message: "User created successfully!", type: "success" });
      setTimeout(()=>{
        navigate("/master/user-master");
      }, 1500);
    } catch (error) {
      console.error("Error creating user:", error);
      console.log(formData);
      setToast({
        message: "Failed to create user. Please try again.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md max-w-lg mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center">Create New User</h2>

      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Email */}
        <div>
          <label className="block font-medium mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Mobile */}
        <div>
          <label className="block font-medium mb-1" htmlFor="mobile">
            Mobile
          </label>
          <input
            type="text"
            name="mobile"
            id="mobile"
            value={formData.mobile}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Gender */}
        <div>
          <label className="block font-medium mb-1" htmlFor="gender">
            Gender
          </label>
          <select
            name="gender"
            id="gender"
            value={formData.gender}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-lg"
          >
            <option value="">Select your Gender</option>
            <option value="Man">Man</option>
            <option value="Woman">Woman</option>
            <option value="Non-Binary">Non-Binary</option>
          </select>
        </div>

        {/* First Name */}
        <div>
          <label className="block font-medium mb-1" htmlFor="first_name">
            First Name
          </label>
          <input
            type="text"
            name="first_name"
            id="first_name"
            value={formData.first_name}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Last Name */}
        <div>
          <label className="block font-medium mb-1" htmlFor="last_name">
            Last Name
          </label>
          <input
            type="text"
            name="last_name"
            id="last_name"
            value={formData.last_name}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Role Dropdown */}
        <div>
          <label className="block font-medium mb-1" htmlFor="role">
            Role
          </label>
          <select
            name="role"
            id="role"
            value={formData.role}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-lg"
          >
            <option value="">Select a Role</option>
            <option value="Admin">Admin</option>
            <option value="General">General</option>
            <option value="Coach">Coach</option>
          </select>
        </div>
        {/* Sport Dropdown */}
        {formData.role === "Coach" && (
          <div>
            <label className="block font-medium mb-1" htmlFor="Sport">
              Sport
            </label>
            <select
              name="Sport"
              id="Sport"
              value={formData.Sport}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
            >
              <option value="">Select a Sport</option>
              {sports.map((sport) => (
                <option key={sport.SportId} value={sport.SportType}>
                  {sport.SportType}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-[#2D1066] text-white py-2 rounded-lg hover:bg-[#512D9A] transition"
          disabled={loading}
        >
          {loading ? "Creating User..." : "Create User"}
        </button>
      </form>
    </div>
  );
};

export default CreateUser;
