import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthContext";
import {
  createCoachingSession,
  createCoachingSlot,
  fetchSponsors,
  fetchSports,
  getUsers,
  uploadEventImage,
  downloadFile,
} from "../api/api"; // Import API functions
import ToastNotification from "../components/ToastNotification";
import CropperEventImage from "../components/CropperEventImage";
import CreateCoachingAllocation from "./CreateCoachingAllocation";
import Loader from "../components/Loader";
import { FaSyncAlt, FaPlus } from "react-icons/fa";

const CreateCoaching = () => {
  const navigate = useNavigate();

  // Initialize the state with properly formatted datetime values
  const [formData, setFormData] = useState({
    CoachId: "" || null,
    CoachingTitle: "",
    CoachingDescription: "",
    CoachingType: "League", // Default to League, you can change it dynamically
    CoachingImages: [],
    CoachingTags: "",
    CoachingLevel: ["Novice"],
    CoachingAccess: "Open",
    SportId: "", // The ID of the sport related to the event
    MaxLearners: 0, // Maximum number of learners allowed
    PriceCurrency: "INR", // Currency for the price, defaulted to INR
    isPaid: true, // Boolean indicating if the event is paid
    InviteList: [],
    FAQ: [
      {
        question: "",
        answer: "",
      },
    ],
    SponsorIds: [],
  });

  const [sponsors, setSponsors] = useState([]);
  const [sports, setSports] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const [usedCropOptions, setUsedCropOptions] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [filteredCoaches, setFilteredCoaches] = useState([]);
  const [coachingId, setCoachingId] = useState(null);
  const [showSlotOverlay, setShowSlotOverlay] = useState(false);
  const [slots, setSlots] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [sponsorImages, setSponsorImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState(null);
  const { accessToken } = useAuthContext();

  useEffect(() => {
    const fetchCoaches = async () => {
      try {
        const usersData = await getUsers();
        const coachList = usersData.users.filter(
          (user) => user.RoleType === "Coach"
        );

        setCoaches(coachList);
      } catch (error) {
        console.error("Error fetching coaches:", error);
        setToast({ message: "Failed to fetch coaches.", type: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchCoaches();
  }, []);

  const filterCoachesBySport = (sportId) => {
    if (!sportId) {
      setFilteredCoaches([]); // Clear filtered coaches if no sport is selected
      return;
    }
  
    const filtered = coaches.filter((coach) =>
      coach.PreferredSportsId?.includes(sportId)
    );
  
    setFilteredCoaches(filtered);
  };
    

  // Fetch required data for Venues, Sponsors, and Sports
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedSponsors = await fetchSponsors();
        const fetchedSports = await fetchSports();

        setSponsors(fetchedSponsors);
        setSports(fetchedSports);

        // Fetch sponsor images
        const imagePromises = fetchedSponsors.map(async (sponsor) => {
          if (sponsor.SponsorImages && sponsor.SponsorImages.length > 0) {
            const imageId = sponsor.SponsorImages[0].ImageId; // Get the first image's ID
            try {
              const imageUrl = await downloadFile(imageId);
              return { SponsorId: sponsor.SponsorId, imageUrl };
            } catch {
              return { SponsorId: sponsor.SponsorId, imageUrl: null };
            }
          }
          return { SponsorId: sponsor.SponsorId, imageUrl: null };
        });

        const images = await Promise.all(imagePromises);
        const imagesById = images.reduce((acc, item) => {
          acc[item.SponsorId] = item.imageUrl;
          return acc;
        }, {});

        setSponsorImages(imagesById);
      } catch (error) {
        console.error("Error fetching details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const refreshSponsors = async () => {
    setLoading(true);
    try {
      const fetchedSponsors = await fetchSponsors();
      setSponsors(fetchedSponsors);

      // Fetch images for the new list of sponsors
      const imagePromises = fetchedSponsors.map(async (sponsor) => {
        if (sponsor.SponsorImages && sponsor.SponsorImages.length > 0) {
          const imageId = sponsor.SponsorImages[0].ImageId;
          try {
            const imageUrl = await downloadFile(imageId);
            return { SponsorId: sponsor.SponsorId, imageUrl };
          } catch {
            return { SponsorId: sponsor.SponsorId, imageUrl: null };
          }
        }
        return { SponsorId: sponsor.SponsorId, imageUrl: null };
      });

      const images = await Promise.all(imagePromises);
      const imagesById = images.reduce((acc, item) => {
        acc[item.SponsorId] = item.imageUrl;
        return acc;
      }, {});

      setSponsorImages(imagesById);

      setToast({
        message: "Sponsors refreshed successfully!",
        type: "success",
      });
    } catch (error) {
      console.error("Error refreshing sponsors:", error);
      setToast({ message: "Failed to refresh sponsors.", type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const fetchImageUrl = async (fileId) => {
    try {
      const imageUrl = await downloadFile(fileId);
      return imageUrl;
    } catch (error) {
      console.error("Error fetching image URL:", error);
      return null;
    }
  };

  // Update handleImageCrop function to fetch image URL and set it for preview
  const handleImageCrop = async (blob, tag) => {
    const formData = new FormData();
    formData.append("data", blob);
    formData.append("alt_text", tag);

    try {
      const response = await uploadEventImage(formData, accessToken);
      if (response && response.FileId) {
        const imageUrl = await fetchImageUrl(response.FileId);
        if (imageUrl) {
          setFormData((prevData) => ({
            ...prevData,

            CoachingImages: [
              ...prevData.CoachingImages,
              { FileId: response.FileId, Tags: [tag] },
            ],
          }));

          setUsedCropOptions((prevOptions) =>
            prevOptions.includes(tag) ? prevOptions : [...prevOptions, tag]
          );

          setToast({
            message: "Image uploaded successfully!",
            type: "success",
          });
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setToast({ message: "Image upload failed!", type: "error" });
    } finally {
      setShowCropper(false);
    }
  };

  const handlePreviewImage = (fileId) => {
    const image = formData.CoachingImages.find((img) => img.FileId === fileId);
    if (image && image.Url) {
      setPreviewImage(image.Url); // Use the image URL as the source
    }
  };
  // Remove Image
  const handleRemoveImage = (fileId) => {
    const imageToRemove = formData.CoachingImages.find(
      (image) => image.FileId === fileId
    );

    if (imageToRemove) {
      setUsedCropOptions((prevOptions) =>
        prevOptions.filter((option) => option !== imageToRemove.Tags[0])
      );
    }

    setFormData((prevData) => ({
      ...prevData,

      CoachingImages: prevData.CoachingImages.filter(
        (image) => image.FileId !== fileId
      ),
    }));
    setToast({ message: "Image removed successfully!", type: "success" });
  };

  // Add this function to check if all crop options are used
  const isAllCropOptionsUsed = () => {
    const allCropOptions = ["SPOTLIGHT", "TALL", "WIDE", "SQUARE"];
    return allCropOptions.every((option) => usedCropOptions.includes(option));
  };

  // Updated Add Image button with disabled state and toast notification
  const handleAddImageClick = () => {
    if (isAllCropOptionsUsed()) {
      setToast({
        message: "All images have been uploaded. No need to add more.",
        type: "info",
      });
    } else {
      setShowCropper(true);
    }
  };

  // Handles opening the slot creation overlay
  const handleAddSlot = () => {
    setShowSlotOverlay(true);
  };

  // Handles closing the slot creation overlay
  const handleCloseSlotOverlay = () => {
    setShowSlotOverlay(false);
  };

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "SportId") {
      filterCoachesBySport(value); // Filter coaches when sport changes
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle adding a new tag
  const handleTagChange = (e) => {
    const value = e.target.value; // Selected tag value
    // if (value && !formData.CoachingTags.includes(value)) {
    //   // Add the selected tag if it's not already in the array
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     CoachingTags: [...prevData.CoachingTags, value],
    //   }));
    // }
    // Reset dropdown selection
    e.target.value = "";
  };

  // Handle removing a tag
  const handleRemoveTag = () => {
    setFormData((prevData) => ({
      ...prevData,
      CoachingTags: "", // Clear the tag
    }));
  };

  // Ensure MaxPlayers is not negative
  const handleMaxLearnersChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      MaxLearners: value >= 0 ? value : prevData.MaxLearners,
    }));
  };

  // Handle checkbox changes for Sponsors
  const handleSponsorChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      SponsorIds: checked
        ? [...prevData.SponsorIds, value]
        : prevData.SponsorIds.filter((id) => id !== value),
    }));
  };

  // Handle checkbox change for isPaid
  const handleIsPaidChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      isPaid: checked,
    }));
  };

  const handleFAQChange = (index, e) => {
    const { name, value } = e.target;
    const newFaqs = [...formData.FAQ];
    newFaqs[index] = { ...newFaqs[index], [name]: value };
    setFormData((prevData) => ({
      ...prevData,
      FAQ: newFaqs,
    }));
  };

  const addFAQ = () => {
    setFormData((prevData) => ({
      ...prevData,
      FAQ: [...prevData.FAQ, { question: "", answer: "" }],
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth")).token
      : null;

    if (!token) {
      setToast({
        message: "Access token is missing. Please log in.",
        type: "error",
      });
      return;
    }

    // Prepare updatedFormData
    let updatedFormData = {
      ...formData,
      MaxLearners: Number(formData.MaxLearners),
      CoachingLevel: Array.isArray(formData.CoachingLevel)
        ? formData.CoachingLevel
        : [formData.CoachingLevel],
    };

    // Validate at least one image
    if (
      !updatedFormData.CoachingImages ||
      updatedFormData.CoachingImages.length === 0
    ) {
      setToast({
        message: "Please upload at least one session image.",
        type: "error",
      });
      return;
    }

    try {
      const response = await createCoachingSession(updatedFormData, token); // API call
      const newCoachingId = response.CoachingId; // Extract CoachingId from response
      setCoachingId(newCoachingId);

      // Step 2: Create Slots
      const slotPromises = slots.map((slot) => {
        const slotPayload = {
          ...slot,
          CoachOfferingId: newCoachingId, // Attach the CoachingId
        };
        return createCoachingSlot(slotPayload);
      });
      await Promise.all(slotPromises);

      setToast({
        message: "Coaching with slots created successfully!",
        type: "success",
      });
      console.log("Payload to be sent:", updatedFormData);
      // navigate("/coaching");
    } catch (error) {
      console.error("Error creating Coaching or slots:", error);
      console.log("Payload to be sent:", updatedFormData);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-center">
        Create New Coaching Session
      </h2>
      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
      <form onSubmit={handleSubmit}>
        {/* Sport Dropdown */}
        <div className="mb-6">
          <label htmlFor="SportId" className="block text-lg font-semibold mb-2">
            Select Sport *
          </label>
          <select
            name="SportId"
            id="SportId"
            value={formData.SportId}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select a Sport</option>
            {sports.map((sport) => (
              <option key={sport.SportId} value={sport.SportId}>
                {sport.SportType}
              </option>
            ))}
          </select>
        </div>
        {/* Coach ID Dropdown */}
        <div className="mb-6">
          <label htmlFor="CoachId" className="block text-lg font-semibold mb-2">
            Select Coach *
          </label>
          <select
            name="CoachId"
            id="CoachId"
            value={formData.CoachId}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
            disabled={!formData.SportId} // Disable if no sport is selected
          >
            <option value="">
              {formData.SportId
                ? "Select a Coach"
                : "Please select a sport first"}
            </option>
            {filteredCoaches.map((coach) => (
              <option key={coach.UserId} value={coach.UserId}>
                {coach.FirstName} {coach.LastName} ({coach.EmailAddress})
              </option>
            ))}
          </select>
        </div>

        {/* Coaching Title */}
        <div className="mb-6">
          <label
            htmlFor="CoachingTitle"
            className="block text-lg font-semibold mb-2"
          >
            Coaching Title *
          </label>
          <input
            type="text"
            name="CoachingTitle"
            id="CoachingTitle"
            value={formData.CoachingTitle}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Coaching Description */}
        <div className="mb-6">
          <label
            htmlFor="CoachingDescription"
            className="block text-lg font-semibold mb-2"
          >
            Coaching Description *
          </label>
          <textarea
            name="CoachingDescription"
            id="CoachingDescription"
            value={formData.CoachingDescription}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Coaching Level */}
        {/* <div className="mb-6">
          <label
            htmlFor="CoachingLevel"
            className="block text-lg font-semibold mb-2"
          >
            Skill Level
          </label>
          <select
            name="CoachingLevel"
            id="CoachingLevel"
            value={formData.CoachingLevel}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
          >
            <option value="Novice">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Professional">Professional</option>
          </select>
        </div> */}

        {/* Uploaded Images */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-4">Uploaded Images</h3>
          {formData.CoachingImages.length === 0 ? (
            <p className="text-gray-500">No images uploaded yet.</p>
          ) : (
            <ul className="space-y-2">
              {formData.CoachingImages.map((image, index) => (
                <li
                  key={image.FileId}
                  className="flex items-center justify-between bg-gray-100 p-3 rounded-lg"
                >
                  <div>
                    <p className="font-medium">Image {index + 1}</p>
                    <p className="text-sm text-gray-500">
                      Tags: {image.Tags.join(", ")}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    {/* Preview Button */}
                    <button
                      type="button" // Prevents form submission
                      onClick={() => handlePreviewImage(image.FileId)}
                      className="text-blue-500 hover:underline"
                    >
                      Preview
                    </button>
                    {/* Remove Button */}
                    <button
                      type="button" // Prevents form submission
                      onClick={() => handleRemoveImage(image.FileId)}
                      className="text-red-500 hover:underline"
                    >
                      Remove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}

          {/* Image Preview Overlay */}
          {previewImage && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-4 rounded-lg shadow-lg relative max-w-lg w-full max-h-[80vh] overflow-auto">
                <button
                  type="button"
                  onClick={() => setPreviewImage(null)}
                  className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 text-2xl"
                >
                  ✕
                </button>
                <div className="flex items-center justify-center">
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="max-w-full max-h-[60vh] object-contain"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Add Image Button */}
        <div className="mb-6">
          <button
            type="button"
            onClick={handleAddImageClick}
            disabled={isAllCropOptionsUsed()}
            className={`bg-blue-500 text-white p-2 rounded-lg transition duration-200 ${
              isAllCropOptionsUsed()
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-600"
            }`}
          >
            Add Image
          </button>
        </div>

        {/* Cropping Tool Overlay */}
        {showCropper && (
          <CropperEventImage
            onCropComplete={handleImageCrop}
            onClose={() => setShowCropper(false)}
            usedCropOptions={usedCropOptions}
          />
        )}

        {/* CoachingTags Dropdown */}
        <div className="mb-6">
          <label
            htmlFor="CoachingTags"
            className="block text-lg font-semibold mb-2"
          >
            Coaching Tag
          </label>
          <select
            name="CoachingTags"
            id="CoachingTags"
            value={formData.CoachingTags}
            onChange={handleInputChange} // Use the existing handler
            className="w-full p-3 border border-gray-300 rounded-lg"
          >
            <option value="">None</option>
            <option value="Spotlight">Spotlight</option>
          </select>

          {/* Display selected tags */}
          {formData.CoachingTags && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">Selected Tag</h3>
              <div className="flex items-center bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm shadow-md">
                {formData.CoachingTags}
                <button
                  type="button"
                  onClick={handleRemoveTag}
                  className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                >
                  ✕
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Sponsors Checkbox List */}
        <div className="mb-6">
          <div className="flex items-center justify-between mb-2">
            <label
              htmlFor="SponsorIds"
              className="block text-lg font-semibold mb-2"
            >
              Sponsors
            </label>
            <div className="flex space-x-3">
              <button
                type="button"
                onClick={refreshSponsors}
                className="text-gray-500 hover:text-gray-700 flex items-center justify-center transition duration-200 p-2 rounded-lg"
              >
                <FaSyncAlt className="text-xl" />
              </button>
              <button
                type="button"
                onClick={() => window.open("/sponsors/create", "_blank")}
                className="text-gray-500 hover:text-gray-700 flex items-center justify-center transition duration-200 p-2 rounded-lg"
              >
                <FaPlus className="text-xl" />
              </button>
            </div>
          </div>
          <div className="flex flex-wrap gap-4">
            {sponsors.map((sponsor) => (
              <div
                key={sponsor.SponsorId}
                className="flex items-center space-x-4 p-2 border rounded-lg shadow-sm bg-gray-50"
              >
                {/* Sponsor Image */}
                {sponsorImages[sponsor.SponsorId] ? (
                  <img
                    src={sponsorImages[sponsor.SponsorId]}
                    alt={sponsor.SponsorName}
                    className="w-12 h-12 object-cover rounded-full"
                  />
                ) : (
                  <div className="w-12 h-12 bg-gray-300 rounded-full flex items-center justify-center">
                    <span className="text-sm text-gray-600">No Image</span>
                  </div>
                )}

                {/* Checkbox and Name */}
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id={`sponsor-${sponsor.SponsorId}`}
                      name="SponsorIds"
                      value={sponsor.SponsorId}
                      checked={formData.SponsorIds.includes(sponsor.SponsorId)}
                      onChange={handleSponsorChange}
                      className="h-5 w-5"
                    />
                    <label
                      htmlFor={`sponsor-${sponsor.SponsorId}`}
                      className="text-lg font-medium"
                    >
                      {sponsor.SponsorName} ({sponsor.SponsorType})
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Max Learners */}
        <div className="mb-6">
          <label
            htmlFor="MaxLearners"
            className="block text-lg font-semibold mb-2"
          >
            Total Capacity
          </label>
          <input
            type="number"
            name="MaxLearners"
            id="MaxLearners"
            value={formData.MaxLearners}
            onChange={handleMaxLearnersChange}
            min="1" // Prevent negative values
            className="w-full p-3 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Price and isPaid Checkbox */}
        {/* <div className="mb-6">
          <label htmlFor="isPaid" className="block text-lg font-semibold mb-2">
            Is Paid
          </label>
          <input
            type="checkbox"
            name="isPaid"
            id="isPaid"
            checked={formData.isPaid}
            onChange={handleIsPaidChange}
            className="mr-2"
          />
        </div> */}

        {/* FAQ Section */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">FAQs</label>
          {formData.FAQ.map((faq, index) => (
            <div key={index} className="mb-4">
              <input
                type="text"
                name="question"
                placeholder="Question"
                value={faq.question}
                onChange={(e) => handleFAQChange(index, e)}
                className="w-full p-3 border border-gray-300 rounded-lg mb-2"
              />
              <textarea
                name="answer"
                placeholder="Answer"
                value={faq.answer}
                onChange={(e) => handleFAQChange(index, e)}
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={addFAQ}
            className="bg-blue-500 text-white p-2 rounded-lg"
          >
            Add FAQ
          </button>
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-bold mb-4">Slots</h3>
          {slots.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-4">
              {slots.map((slot, index) => (
                <div
                  key={index}
                  className="bg-white border border-gray-200 shadow-md rounded-lg p-5 hover:shadow-lg transition-shadow duration-300"
                >
                  <h4 className="text-lg font-semibold text-gray-800 mb-3">
                    Slot {index + 1}
                  </h4>
                  <div className="text-sm text-gray-600 space-y-2">
                    <p>
                      <span className="font-medium text-gray-700">Time:</span>{" "}
                      {slot.Slot}
                    </p>
                    <p>
                      <span className="font-medium text-gray-700">
                        Duration:
                      </span>{" "}
                      {slot.Duration} minutes
                    </p>
                    <p>
                      <span className="font-medium text-gray-700">Price:</span>{" "}
                      ₹{slot.Price}
                    </p>
                  </div>
                  <button
                    className="mt-4 border border-[#cf1909] border-2 text-[#cf1909] py-2 px-4 rounded-lg text-sm font-medium hover:bg-[#F68F8F] hover:text-[#ffffff] hover:border-[#f68f8f] transition-colors duration-200"
                    onClick={() =>
                      setSlots((prevSlots) =>
                        prevSlots.filter((_, i) => i !== index)
                      )
                    }
                  >
                    Remove Slot
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500 mb-2">No slots added yet.</p>
          )}

          {/* Add Slot Button */}
          <button
            type="button"
            onClick={() => setShowSlotOverlay(!showSlotOverlay)}
            className="bg-blue-500 text-white p-2 rounded-lg"
          >
            {showSlotOverlay ? "Close Slot Overlay" : "Add Slot"}
          </button>

          {/* Slot Overlay */}
          {showSlotOverlay && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl max-h-[90vh] overflow-auto relative">
                {/* Close Button */}
                <button
                  onClick={() => setShowSlotOverlay(false)}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 text-2xl"
                >
                  ✕
                </button>
                <div className="p-6">
                  <CreateCoachingAllocation
                    onClose={() => setShowSlotOverlay(false)}
                    onAddSlot={(newSlot) =>
                      setSlots((prevSlots) => [...prevSlots, newSlot])
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-blue-500 text-white p-3 rounded-lg mt-4 w-full"
        >
          Create Coaching Session
        </button>
      </form>
    </div>
  );
};

export default CreateCoaching;
