import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // To get event ID from the URL
import { fetchPlaypassById, fetchUserDetails, getTeams, updatePlaypass } from "../../api/api";
import { useNavigate } from "react-router-dom";
import {
    FaClipboardList,
    FaEdit,
    FaChevronDown,
    FaChevronUp,
} from "react-icons/fa";
import axios from "axios";
import EditPlaypassForm from "../../components/EditPlaypassForm";

const PlaypassDetails = () => {

    const { id } = useParams();
    const [playpass, setPlaypass] = useState(null)
    const [organizerInfo, setOrganizerInfo] = useState(null)
    const [playerDetails, setPlayerDetails] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [inviteList, setInviteList] = useState([])
    const [teamInviteList, setTeamInviteList] = useState([])
    const [showSuccessNotification, setShowSuccessNotification] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // To toggle the editing mode



    // Fetch event details when the component mounts
    useEffect(() => {

        const getPlaypassDetails = async () => {
            try {
                //setting playpass data
                const playpassData = await fetchPlaypassById(id);
                console.log("viewing playpass data")
                console.log(playpassData.games[0])
                setPlaypass(playpassData.games[0]);



            } catch (error) {
                setError("Failed to load event details");
                console.error("Error fetching event details:", error);
            } finally {
                setLoading(false);
            }
        };
        getPlaypassDetails();
    }, [id]);

    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            try {
                //fetching user details and invite list
                console.log("playpass?. org id: ",playpass?.OrganizerId)
                const userId = playpass?.OrganizerId

                const OrganizerDetails = await fetchUserDetails(userId)
                console.log("org details: ", OrganizerDetails)


                setOrganizerInfo(OrganizerDetails.User)

                if (playpass?.InviteList?.length > 0) {

                    // Create an array of promises for fetching invite list details
                    const promises = playpass.InviteList.map((inviteId) =>
                        fetchUserDetails(inviteId)
                    );
                    // Wait for all promises to resolve
                    const inviteResponses = await Promise.all(promises);
                    // Filter out any null responses
                    const validResponses = inviteResponses.filter((invite) => invite !== null);

                    // Set the invite list and player details state
                    setInviteList(validResponses);
                    setPlayerDetails(validResponses);
                }

            } catch (error) {
                console.error("Error with fetching user details in playpass details: ", error)
            } finally {
                setLoading(false)
            }
        }
        fetchdata()
    }, [playpass])


    useEffect(() => {
        const fetchData = async () => {

            setLoading(true)

            try {

                if (playpass?.TeamInviteList?.length > 0) {

                    const teams = await getTeams();
                  

                    if (teams.length > 0) {
                        const filteredTeams = teams.filter((team) =>
                            playpass.TeamInviteList.includes(team.TeamId) // Check if the team's ID exists in TeamInviteList
                        );

                        setTeamInviteList(filteredTeams)
                    }
                }
            } catch (error) {
                console.log("error fetching team data: ", error)
            } finally {
                setLoading(false)
            }

        }
        fetchData()

    }, [playpass])

    const handleEditClick = () => {
        setIsEditing(true); // Enable editing when the Edit button is clicked
    };

    // Handling Cancel Edit
    const handleCancelEdit = () => {
        setIsEditing(false); // Disable editing when cancel button is clicked
    };

    // Save changes to the playpass
      const handleSaveChanges = async (updatedPlaypassData) => {
        try {
          // Do NOT remove the EventId, pass it along with the updated data
          const updatedPlaypass = await updatePlaypass(playpass.PlayPassId, updatedPlaypassData);
    
          // If the updated event doesn't include all details, merge the old event data
          const finalPlaypass = {
            ...playpass, // Preserve existing event data
            ...updatedPlaypass, // Override with updated data
          };
    
          // Update the event data in state with the merged event data
          setPlaypass(finalPlaypass); // Save the updated event data to state
          setIsEditing(false); // Disable editing mode
    
          if (updatedPlaypass && updatedPlaypass.PlayPassId) {
            setShowSuccessNotification(true);
    
            // Hide notification after 3 seconds
            setTimeout(() => {
              setShowSuccessNotification(false);
            }, 3000);
          }
        } catch (error) {
          setError("Failed to save changes");
          console.error("Error saving event:", error);
        }
      };

    return (
        <div className="container mx-auto p-6 relative">

            {/* Success Notification */}
            {showSuccessNotification && (
                <div className="absolute top-6 right-6 p-4 bg-green-500 text-white rounded-lg shadow-lg flex items-center">
                    <span className="mr-2">✔️</span>
                    <span>Event successfully updated!</span>
                </div>
            )}
            <div className="absolute top-8 right-8 flex flex-col lg:flex-row gap-2">
                {/* Edit Button */}
                <button
                    className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium  flex items-center justify-center"
                    onClick={handleEditClick} // Trigger editing mode when clicked
                >
                    {/* Show text on larger screens */}
                    <span className="hidden sm:inline">Edit Playpass</span>
                    {/* Show icon on small screens */}
                    <FaEdit className="h-5 w-5 sm:hidden" />
                </button>

                {/* Show Event Registrations Button */}
                <button
                    className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium  flex items-center justify-center"
                // onClick={handleShowMembersClick} // Trigger event members page redirection
                >
                    {/* Show text on larger screens */}
                    <span className="hidden sm:inline">Update Score</span>
                    {/* Show icon on small screens */}
                    <FaClipboardList className="h-5 w-5 sm:hidden" />
                </button>


                <button
                    className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium  flex items-center justify-center"
                // onClick={handleShowMembersClick} // Trigger event members page redirection
                >
                    {/* Show text on larger screens */}
                    <span className="hidden sm:inline">Edit Teams</span>
                    {/* Show icon on small screens */}
                    <FaClipboardList className="h-5 w-5 sm:hidden" />
                </button>
            </div>



            {/* Playpass game Details */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    {playpass?.PlayPassTitle}
                </h2>

                <p className="text-lg text-gray-700 mb-4">
                    <strong>Description:</strong> {playpass?.Description}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Organizer:</strong> {organizerInfo?.FirstName}{" "}{organizerInfo?.LastName}
                </p>
            </div>

            {/* Playpass game information Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h3 className="text-4xl font-bold text-gray-900 mb-4">
                    Playpass Game Information
                </h3>
                <div className="space-y-4">
                    <p className="text-lg text-gray-700">
                        <strong>Playpass Type:</strong> {playpass?.PlayPassType}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Playpass access:</strong>{" "}
                        {playpass?.PlayPassAccess || "Not Available"}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Skill Level:</strong> {playpass?.SkillLevel+" "}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Team Size:</strong> {playpass?.TeamSize}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Total Teams:</strong> {playpass?.TotalTeams}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Registered Players:</strong> {playpass?.RegisteredPlayers}
                    </p>

                </div>
            </div>

            {/* Invite List */}

            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Invite List
                </h2>
                {playpass?.InviteList?.length > 0 ? (
                    <div className="text-lg text-gray-700 mb-4">
                        {playpass.InviteList.map((player, index) => (
                            <div key={index} className="mb-2">
                                <p className="font-semibold">Player {index + 1}: {inviteList[index]?.User.FirstName}{" "} {inviteList[index]?.User.LastName} </p>
                            </div>
                        ))}
                    </div>
                ) : ("No invite list found!")}


            </div>


            {/* Team invite list */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Team Invite List
                </h2>
                {playpass?.TeamInviteList?.length > 0 ? (
                    <div className="text-lg text-gray-700 mb-4">
                        {playpass.TeamInviteList.map((player, index) => (
                            <div key={index} className="mb-2">
                                <p>Team {index + 1}: {teamInviteList[index]?.TeamName}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-700">No team invites available.</p>
                )}
            </div>


            {/* Venue Details */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Venue
                </h2>

                <p className="text-lg text-gray-700 mb-4">
                    <strong>Name:</strong> {playpass?.VenueName}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Map Link: </strong>  <a className="text-blue-700" target="_blank" href={`${playpass?.VenueMapLink}`}> {playpass?.VenueMapLink} </a>
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Play Area: </strong> {playpass?.VenueAllocation?.PlayArea}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Slot: </strong>{new Date(playpass?.VenueAllocation?.Slot).toLocaleDateString(
                        "en-US",
                        {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        }
                    )}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Duration: </strong> {playpass?.VenueAllocation?.Duration} mins</p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Price (INR): </strong> {playpass?.VenueAllocation?.Price}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Price (Credits): </strong> {playpass?.VenueAllocation?.PriceCredits}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Is Booked: </strong> {playpass?.VenueAllocation[0]?.IsBooked ? "Yes" : "No"}
                </p>
            </div>

            

            {/* FAQs*/}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    FAQs
                </h2>
                {playpass?.FAQ?.length > 0 ? (
                    <div className="text-lg text-gray-700">
                        {playpass.FAQ.map((faq, index) => (
                            <div key={index} className="mb-4">
                                <p className="font-bold ">Ques {index + 1}: {faq.question}</p>
                                <p className="font-semibold">Ans: {faq.answer}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-700">No FAQs available.</p>
                )}

            </div>

            {/* Edit Event Form Overlay */}
            {isEditing && (
                <EditPlaypassForm
                    playpass={playpass}
                    onSave={handleSaveChanges}
                    onCancel={handleCancelEdit}
                />
            )}

        </div>
    )
}

export default PlaypassDetails
