import React, { useState, useEffect } from "react";
import {
  fetchVenues,
  fetchCoachingEvents,
  createCoachingSlot,
} from "../api/api";
import ToastNotification from "../components/ToastNotification";

const CreateCoachingAllocation = ({ coachingId, onClose, onAddSlot }) => {
  const [formData, setFormData] = useState({
    VenueId: "",
    CoachArea: "",
    Slot: "",
    Duration: "",
    Price: 0,
    PriceCredits: 0,
    Schedule: {
      // StartTimeStamp: "",
      EndTimeStamp: "",
      IsRecurring: false,
      RecurrenceRule: "",
      RecurrenceInterval: 1,
      RecurrenceEndDate: "",
      RecurrenceDaysOfWeek: [],
    },
  });

  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState(null);
  const [calculatedEndTime, setCalculatedEndTime] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fetchedVenues] = await Promise.all([fetchVenues()]);
        setVenues(fetchedVenues);
      } catch (error) {
        console.error("Error fetching data:", error);
        setToast({ message: "Failed to fetch data.", type: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.includes("Schedule.")) {
      const field = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        Schedule: {
          ...prevData.Schedule,
          [field]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    if (formData.Slot && formData.Duration) {
      const slotDateTime = new Date(formData.Slot);
      const durationMinutes = parseInt(formData.Duration, 10);
      if (!isNaN(durationMinutes)) {
        // Add the duration in minutes to the selected slot time
        const endDateTime = new Date(slotDateTime.getTime() + durationMinutes * 60000);
  
        // Format the date to 'yyyy-MM-ddTHH:mm' in local time
        const year = endDateTime.getFullYear();
        const month = String(endDateTime.getMonth() + 1).padStart(2, "0");
        const date = String(endDateTime.getDate()).padStart(2, "0");
        const hours = String(endDateTime.getHours()).padStart(2, "0");
        const minutes = String(endDateTime.getMinutes()).padStart(2, "0");
  
        setCalculatedEndTime(`${year}-${month}-${date}T${hours}:${minutes}`);
      }
    } else {
      setCalculatedEndTime("");
    }
  }, [formData.Slot, formData.Duration]);
  

  const handleRecurrenceDaysChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      Schedule: {
        ...prevData.Schedule,
        RecurrenceDaysOfWeek: checked
          ? [...prevData.Schedule.RecurrenceDaysOfWeek, value]
          : prevData.Schedule.RecurrenceDaysOfWeek.filter(
              (day) => day !== value
            ),
      },
    }));
  };

  const handleAddSlot = async (e) => {
    e.preventDefault();

    // Helper function to format the date-time as UTC
    const toExactUTC = (dateTime) => {
      if (!dateTime) return null;

      // Split the dateTime into date and time
      const [date, time] = dateTime.split("T");
      return `${date}T${time}:00.000Z`; // Append seconds, milliseconds, and Z
    };

    try {
      const adjustedFormData = {
        ...formData,
        Slot: toExactUTC(formData.Slot), // Format Slot to exact UTC
        Duration: Number(formData.Duration),
        Price: Number(formData.Price),
        PriceCredits: Number(formData.PriceCredits),

        Schedule: {
          ...formData.Schedule,
          // StartTimeStamp: toExactUTC(formData.Schedule.StartTimeStamp), // Format StartTimeStamp to exact UTC
          EndTimeStamp: toExactUTC(calculatedEndTime), // Format EndTimeStamp to exact UTC
          RecurrenceRule: formData.Schedule.IsRecurring
          ? formData.Schedule.RecurrenceRule
          : null,
        RecurrenceInterval: formData.Schedule.IsRecurring
          ? formData.Schedule.RecurrenceInterval
          : null,
        RecurrenceEndDate: formData.Schedule.IsRecurring
          ? formData.Schedule.RecurrenceEndDate
          : null,
        RecurrenceDaysOfWeek: formData.Schedule.IsRecurring
          ? formData.Schedule.RecurrenceDaysOfWeek
          : [],
        },
      };

      // console.log("Payload to API:", adjustedFormData);
      const newSlot = { ...adjustedFormData, CoachOfferingId: coachingId }; // Attach CoachingId
      console.log("Slot payload:", newSlot)
      onAddSlot(newSlot);
      onClose(); // Close overlay
    } catch (error) {
      console.error("Error creating coaching allocation:", error);
      setToast({
        message: "Failed to create coaching allocation.",
        type: "error",
      });
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-center">
        Create Slot for Coaching Session
      </h2>
      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
      <form onSubmit={handleAddSlot}>
        {/* Venue Dropdown */}
        <div className="mb-6">
          <label htmlFor="VenueId" className="block text-lg font-semibold mb-2">
            Venue
          </label>
          <select
            name="VenueId"
            value={formData.VenueId}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select a Venue</option>
            {venues.map((venue) => (
              <option key={venue.VenueId} value={venue.VenueId}>
                {venue.VenueName}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-6">
          <label
            htmlFor="Schedule.IsRecurring"
            className="block text-lg font-semibold mb-2"
          >
            Is Recurring
          </label>
          <input
            type="checkbox"
            name="Schedule.IsRecurring"
            checked={formData.Schedule.IsRecurring}
            onChange={handleInputChange}
            className="mr-2"
          />
        </div>

        {formData.Schedule.IsRecurring && (
          <>
            <div className="mb-6">
              <label
                htmlFor="Schedule.RecurrenceRule"
                className="block text-lg font-semibold mb-2"
              >
                Recurrence Rule
              </label>
              <select
                name="Schedule.RecurrenceRule"
                value={formData.Schedule.RecurrenceRule}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
              >
                <option value="">Select Recurrence Rule</option>
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
              </select>
            </div>

            <div className="mb-6">
              <label
                htmlFor="Schedule.RecurrenceInterval"
                className="block text-lg font-semibold mb-2"
              >
                Recurrence Interval (days)
              </label>
              <input
                type="number"
                name="Schedule.RecurrenceInterval"
                value={formData.Schedule.RecurrenceInterval}
                onChange={handleInputChange}
                min="1"
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>

            {formData.Schedule.RecurrenceRule === "WEEKLY" && (
              <div className="mb-6">
                <label
                  htmlFor="Schedule.RecurrenceDaysOfWeek"
                  className="block text-lg font-semibold mb-2"
                >
                  Recurrence Days of Week
                </label>
                <div className="grid grid-cols-2 gap-2">
                  {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((day) => (
                    <label key={day} className="flex items-center">
                      <input
                        type="checkbox"
                        name="Schedule.RecurrenceDaysOfWeek"
                        value={day}
                        checked={formData.Schedule.RecurrenceDaysOfWeek.includes(
                          day
                        )}
                        onChange={handleRecurrenceDaysChange}
                        className="mr-2"
                      />
                      {day}
                    </label>
                  ))}
                </div>
              </div>
            )}

            <div className="mb-6">
              <label
                htmlFor="Schedule.RecurrenceEndDate"
                className="block text-lg font-semibold mb-2"
              >
                Recurrence End Date
              </label>
              <input
                type="date"
                name="Schedule.RecurrenceEndDate"
                value={formData.Schedule.RecurrenceEndDate}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>
          </>
        )}



        {/* Slot */}
        <div className="mb-6">
          <label htmlFor="Slot" className="block text-lg font-semibold mb-2">
            Slot
          </label>
          <input
            type="datetime-local"
            name="Slot"
            value={formData.Slot}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Duration Dropdown */}
        <div className="mb-6">
          <label
            htmlFor="Duration"
            className="block text-lg font-semibold mb-2"
          >
            Duration (in minutes) *
          </label>
          <select
            id="Duration"
            name="Duration"
            value={formData.Duration}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                Duration: Number(e.target.value), // Store the duration as a number
              }))
            }
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select Duration</option>
            <option value="30">30 minutes</option>
            <option value="60">1 hour</option>
            <option value="90">1 hour 30 minutes</option>
            <option value="120">2 hours</option>
          </select>
        </div>

        {/* End Time */}
        <div className="mb-6">
          <label htmlFor="EndTime" className="block text-lg font-semibold mb-2">
            End Time
          </label>
          <input
            type="datetime-local"
            name="EndTime"
            value={calculatedEndTime}
            readOnly
            className="w-full p-3 border border-gray-300 rounded-lg bg-gray-100"
          />
        </div>

        {/* Coach Area */}
        <div className="mb-6">
          <label
            htmlFor="CoachArea"
            className="block text-lg font-semibold mb-2"
          >
            Coach Area
          </label>
          <input
            type="text"
            name="CoachArea"
            value={formData.CoachArea}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Price */}
        <div className="mb-6">
          <label htmlFor="Price" className="block text-lg font-semibold mb-2">
            Price (INR)
          </label>
          <input
            type="number"
            name="Price"
            value={formData.Price}
            onChange={handleInputChange}
            min="0"
            className="w-full p-3 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Price Credits */}
        <div className="mb-6">
          <label
            htmlFor="PriceCredits"
            className="block text-lg font-semibold mb-2"
          >
            Price Credits
          </label>
          <input
            type="number"
            name="PriceCredits"
            value={formData.PriceCredits}
            onChange={handleInputChange}
            min="0"
            className="w-full p-3 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Schedule Inputs */}
        {/* <h3 className="text-xl font-bold mb-4">Schedule</h3> */}
        {/* <div className="mb-6">
          <label
            htmlFor="Schedule.StartTimeStamp"
            className="block text-lg font-semibold mb-2"
          >
            Start Time
          </label>
          <input
            type="datetime-local"
            name="Schedule.StartTimeStamp"
            value={formData.Schedule.StartTimeStamp}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
          />
        </div> */}

        

        

        {/* Submit Button */}
        <button
          type="button"
          onClick={handleAddSlot} // Trigger Add Slot logic
          className="bg-blue-500 text-white px-4 py-2 rounded w-full"
        >
          Add Slot
        </button>
      </form>
    </div>
  );
};

export default CreateCoachingAllocation;
