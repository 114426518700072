import React, { useState, useEffect } from "react";
import { fetchVenues, deleteVenue } from "../api/api";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { FaTrashAlt, FaEye } from "react-icons/fa";

const VenueList = () => {
  const [venues, setVenues] = useState([]);
  const [filteredVenues, setFilteredVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  // Fetch venues
  useEffect(() => {
    const getVenues = async () => {
      try {
        const venueData = await fetchVenues();
        setVenues(venueData);
        setFilteredVenues(venueData); // Initialize filtered venues
      } catch (error) {
        setError("Failed to load venue details");
        console.error("Error fetching venue details:", error);
      } finally {
        setLoading(false);
      }
    };

    getVenues();
  }, []);

  // Handle Delete Venue
  const handleDelete = async (venueId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this venue?"
    );
    if (!confirmDelete) return;

    try {
      await deleteVenue(venueId);
      setVenues((prevVenues) =>
        prevVenues.filter((v) => v.VenueId !== venueId)
      );
      setFilteredVenues((prevVenues) =>
        prevVenues.filter((v) => v.VenueId !== venueId)
      );
    } catch (error) {
      console.error("Error deleting venue:", error);
      alert("Failed to delete venue. Please try again.");
    }
  };

  // Handle Search
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = venues.filter(
      (venue) =>
        venue.VenueId.toLowerCase().includes(query) ||
        venue.VenueName.toLowerCase().includes(query)
    );
    setFilteredVenues(filtered);
  };

  // Navigate to Slot Details
  const handleShowSlots = (venueId) => {
    navigate(`/venues/slots/${venueId}`);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="text-center p-6">
        <p className="text-red-500 font-semibold">{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-center text-gray-800">
        Venue Details
      </h2>

      {/* Search Box */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search by Venue ID or Name"
          value={searchQuery}
          onChange={handleSearch}
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Venue Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredVenues.map((venue) => (
          <div
            key={venue.VenueId}
            className="bg-gray-50 p-6 rounded-lg shadow-md relative"
          >
            {/* Delete Button */}
            <button
              onClick={() => handleDelete(venue.VenueId)}
              className="absolute top-2 right-2 bg-red-100 text-red-500 hover:bg-red-200 rounded-full p-2 shadow transition duration-200"
            >
              <FaTrashAlt size={16} />
            </button>

            {/* Venue Details */}
            <h3 className="text-2xl font-semibold text-gray-700 mb-4">
              {venue.VenueName}
            </h3>
            <p className="text-lg text-gray-600 mb-4">
              {venue.VenueDescription}
            </p>

            {/* Address */}
            <div className="mt-4">
              <h4 className="text-xl font-medium text-gray-700 mb-2">
                Address
              </h4>
              <div className="space-y-1 text-gray-600">
                <p>
                  <strong>City:</strong> {venue?.VenueAddress?.city || "N/A"}
                </p>
                <p>
                  <strong>State:</strong> {venue?.VenueAddress?.state || "N/A"}
                </p>
                <p>
                  <strong>Country:</strong>{" "}
                  {venue?.VenueAddress?.country || "N/A"}
                </p>
                <p>
                  <strong>Pincode:</strong>{" "}
                  {venue?.VenueAddress?.pincode || "N/A"}
                </p>
                <p>
                  <strong>View on Map:</strong>{" "}
                  {venue?.MapLink ? (
                    <a
                      href={venue.MapLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      Open in Google Maps
                    </a>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            </div>

            {/* Show Slots Button */}
            <div className="mt-6">
              <button
                onClick={() => handleShowSlots(venue.VenueId)}
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
              >
                <FaEye className="mr-2" /> Show Slots
              </button>
            </div>
          </div>
        ))}
      </div>

      {filteredVenues.length === 0 && (
        <p className="text-gray-500 mt-4 text-center">No venues found.</p>
      )}
    </div>
  );
};

export default VenueList;
